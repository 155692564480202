import * as React from 'react';

import { NavLink, To } from 'react-router-dom';

import { IconType } from 'react-icons';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { Box, Button, Text, Flex, Image, Heading, Stack, useColorModeValue, Stat, StatLabel, StatNumber, Icon } from '@chakra-ui/react';

interface CardProps {
  to?: To;
  icon: IconType | React.ReactNode;
  heading: string;
  description: string | number;
  linkText?: React.ReactNode;
}

export const Card: React.FunctionComponent<CardProps> = (props) => {
  const { to, heading, description, icon, linkText } = props;

  return (
    <Box maxW={{ base: 'full' }} w={'full'} borderWidth='1px' borderRadius='lg' overflow='hidden' p={5}>
      <Stack align={'start'} spacing={2}>
        <Flex w={16} h={16} align={'center'} justify={'center'} color={'white'} rounded={'full'} bg={useColorModeValue('gray.50', 'gray.800')}>
          <Icon as={icon as IconType} />
        </Flex>

        <Box mt={2}>
          <Heading size='md'>{heading}</Heading>

          <Text noOfLines={2} mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>

        <Button as={NavLink} to={to} variant={'link'} colorScheme={'blue'} size={'sm'}>
          {linkText}
        </Button>
      </Stack>
    </Box>
  );
};

export const InfosCard: React.FunctionComponent<CardProps> = (props) => {
  const { to, heading, description, icon, linkText } = props;

  let mainText = useColorModeValue('gray.800', 'white');
  let iconBox = useColorModeValue('gray.100', 'whiteAlpha.200');
  let iconColor = useColorModeValue('brand.200', 'white');

  return (
    <Flex
      p='20px'
      // h='360px'
      maxW={'345px'}
      borderWidth='1px'
      direction='column'
      alignItems='center'
      borderRadius='20px'
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Flex w='100%' mb='18px'>
        <Flex w='38px' h='38px' align='center' justify='center' borderRadius='50%' me='12px' bg={iconBox}>
          <Icon fontSize={'1.5em'} as={icon as IconType} color={iconColor} />
        </Flex>

        <Text my='auto' fontWeight='600' color={mainText} textAlign='center' fontSize='xl' me='auto'>
          {heading}
        </Text>

        <Button w='38px' h='38px' alignItems={'center'} justifyItems='center' borderRadius='12px' bg={iconBox}>
          <Icon w='24px' h='24px' as={IoEllipsisHorizontalSharp} color={iconColor} />
        </Button>
      </Flex>

      <Image src='https://i.ibb.co/KVwmVGW/Teams-Image.png' mb='10px' />

      <Text noOfLines={2} fontWeight='600' color={mainText} textAlign='start' fontSize='xl' w='100%'>
        {description}
      </Text>

      <Flex mt='10px' justify='space-between' w='100%' align='center'>
        <Button as={NavLink} to={to} variant={'link'} colorScheme={'blue'} size={'sm'}>
          {linkText}
        </Button>
      </Flex>
    </Flex>
  );
};

export const StatsCard: React.FunctionComponent<CardProps> = (props) => {
  const { to, heading, description, icon, linkText } = props;

  return (
    <Stat
      py={'5'}
      rounded={'lg'}
      px={{ base: 4 }}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      backgroundColor={useColorModeValue('gray.50', 'gray.800')}
    >
      <Flex justifyContent={'space-between'}>
        <Box mr={3}>
          <StatLabel fontWeight={'medium'}>{heading}</StatLabel>

          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {description}
          </StatNumber>
        </Box>

        <Box my={'auto'} alignContent={'center'} color={useColorModeValue('gray.700', 'gray.200')}>
          {icon as React.ReactNode}
        </Box>
      </Flex>

      {to && (
        <Flex pt={3}>
          <Button as={NavLink} to={to} variant={'link'} colorScheme={'blue'} size={'sm'}>
            {linkText}
          </Button>
        </Flex>
      )}
    </Stat>
  );
};

export const DisplayCard: React.FunctionComponent<CardProps> = (props) => {
  const { heading, description, linkText, to, icon } = props;
  return (
    <Stat px={{ base: 2, md: 4 }} py={'5'} shadow={'xl'} border={'1px solid'} borderColor={useColorModeValue('gray.800', 'gray.500')} rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {heading}
          </StatLabel>

          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {description}
          </StatNumber>
        </Box>

        <Box my={'auto'} color={useColorModeValue('gray.800', 'gray.200')} alignContent={'center'}>
          {icon as React.ReactNode}
        </Box>
      </Flex>

      {to && (
        <Flex pt={3} direction={'row-reverse'}>
          <Button as={NavLink} to={to} variant={'link'} colorScheme={'blue'} size={'sm'}>
            {linkText}
          </Button>
        </Flex>
      )}
    </Stat>
  );
};
