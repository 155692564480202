import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { api } from '../../app/services/api';
import { PublicCloudVm, publicCloudVmDetail } from '../../app/types/nextcloud';

interface VirtualMachinesState {
  vmData: Array<PublicCloudVm>;

  selectedPublicCloudVm: publicCloudVmDetail;

  error?: string | SerializedError;
  vmStatus: 'idle' | 'loading' | 'failed';
  vmActionStatus: 'idle' | 'loading' | 'failed';
}

const initialState: VirtualMachinesState = {
  vmData: [],

  selectedPublicCloudVm: {},

  vmStatus: 'idle',
  vmActionStatus: 'idle',
};

const publicCloudVmSlice = createSlice({
  name: 'public-cloud-vm',
  initialState,
  reducers: {
    setDockerInstanceStatus: (state, action) => {
      if (action.payload === 'UP') {
        state.selectedPublicCloudVm = { ...state.selectedPublicCloudVm, status: 'ACTIVE' };
      } else if (action.payload === 'DOWN') {
        state.selectedPublicCloudVm = { ...state.selectedPublicCloudVm, status: 'INACTIVE' };
      }
    },
  },
  extraReducers: (builder) => {
    /* LIST */
    builder.addMatcher(api.endpoints?.fetchPublicCloudVirtualMachines.matchPending, (state, args) => {
      state.vmStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.fetchPublicCloudVirtualMachines.matchFulfilled, (state, action) => {
      state.vmData = action.payload;

      state.vmStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.fetchPublicCloudVirtualMachines.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmStatus = 'failed';
    });

    /* READ */
    builder.addMatcher(api.endpoints?.readPublicCLoudVirtualMachine.matchPending, (state, args) => {
      state.vmStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.readPublicCLoudVirtualMachine.matchFulfilled, (state, action) => {
      state.selectedPublicCloudVm = action.payload;

      state.vmStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.readPublicCLoudVirtualMachine.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmStatus = 'failed';
    });
  },
});

export const { setDockerInstanceStatus } = publicCloudVmSlice.actions;

export default publicCloudVmSlice.reducer;
