import { createApi } from '@reduxjs/toolkit/query/react';

import { vCenterVm, vCenterVmDetails } from '../types/vm';
import { FileFormData, User, UserResponse } from '../types/user';
import { Job, JobEvent, LaunchedJobTemplate } from '../types/job';
import { PublicCloudVm, publicCloudVmDetail } from '../types/nextcloud';
import { LoginRequest, RecoverPasswordRequest, RecoverPasswordResponse } from '../types/auth';

import { UserData } from '../../features/users/usersSlice';

import { setToken } from '../../common/utils/helper';
import { refreshTokenFetchBase } from './refreshTokenFetchBase';

export const api = createApi({
  baseQuery: refreshTokenFetchBase,
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (data) => ({
        url: `/v1/auth/local`,
        method: 'POST',
        body: data,
        credentials: 'include',
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          setToken(data.jwt);
        } catch (error) {
          console.error(error);
        }
      },
    }),

    keycloakLogin: builder.mutation<any, { access_token: string; id_token: string; refresh_token: string }>({
      query: ({ access_token, id_token, refresh_token }) => ({
        url: `/v1/auth/keycloak/callback`,
        method: 'GET',
        params: { access_token, id_token, refresh_token },
        credentials: 'include',
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          setToken(data.jwt);
          setToken(args.id_token, 'sessionId');
          setToken(args.refresh_token, 'refreshToken');
        } catch (error) {
          console.error(error);
        }
      },
    }),

    recoverPassword: builder.mutation<RecoverPasswordResponse, RecoverPasswordRequest>({
      query: (data) => ({
        url: '/v1/auth/forgot-password',
        method: 'POST',
        body: data,
      }),
    }),

    readUser: builder.mutation<User, void>({
      query: () => ({
        url: '/v1/users/me?populate=*',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (baseQueryReturnValue: User) => {
        const baseUploadUrl =
          process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_BASE_URL : process.env.REACT_APP_API_BASE_URL;

        return {
          ...baseQueryReturnValue,
          avatar: {
            ...baseQueryReturnValue?.avatar,
            url: !!baseUploadUrl ? baseUploadUrl + baseQueryReturnValue?.avatar?.url : baseQueryReturnValue?.avatar?.url,
          },
        };
      },
    }),

    updateUser: builder.mutation<User, User>({
      query: (data) => ({
        url: `/v1/users/${data.id}`,
        method: 'PUT',
        body: data,
        credentials: 'include',
      }),
    }),

    uploadFile: builder.mutation<File, FileFormData>({
      query: (arg) => ({
        url: '/v1/upload',
        method: 'POST',
        body: arg.file,
        params: { id: arg.id },
        credentials: 'include',
      }),
    }),

    listUsers: builder.mutation<UserData, void>({
      query: () => ({
        url: '/v1/users',
        method: 'GET',
        credentials: 'include',
      }),
      // @ts-ignore
      transformResponse: (response: UserData) => ({ results: response }) as UserData,
    }),

    /* VSPHERE */
    fetchVirtualMachines: builder.mutation<Array<vCenterVm>, void>({
      query: () => {
        return {
          url: '/v1/vsphere/fetchVirtualMachines',
          method: 'GET',
          credentials: 'include',
        };
      },
    }),

    readVirtualMachine: builder.mutation<vCenterVmDetails, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/v1/vsphere/readVirtualMachine`,
          method: 'GET',
          params: { id },
          credentials: 'include',
        };
      },
    }),

    readVirtualMachineInterfaces: builder.mutation<any, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/v1/vsphere/readVirtualMachineInterfaces`,
          method: 'GET',
          params: { id },
          credentials: 'include',
        };
      },
    }),

    virtualMachineAction: builder.mutation<void, { id: string; action: string }>({
      query: ({ id, action }) => {
        return {
          url: `/v1/vsphere/virtualMachineAction`,
          method: 'POST',
          params: { id, action },
          credentials: 'include',
        };
      },
    }),

    virtualMachineActionGuest: builder.mutation<void, { id: string; action: string }>({
      query: ({ id, action }) => {
        return {
          url: `/v1/vsphere/virtualMachineActionGuest`,
          method: 'POST',
          params: { id, action },
          credentials: 'include',
        };
      },
    }),

    virtualMachineToggleNA: builder.mutation<void, { vmId: string; nic: string; action: string }>({
      query: ({ vmId, nic, action }) => {
        return {
          url: `/v1/vsphere/virtualMachineToggleNA`,
          method: 'POST',
          params: { vmId, nic, action },
          credentials: 'include',
        };
      },
    }),

    /* PUBLIC CLOUD */
    fetchPublicCloudVirtualMachines: builder.mutation<Array<PublicCloudVm>, void>({
      query: () => {
        return {
          url: '/v1/public-cloud/fetchPublicCloudVirtualMachines',
          method: 'GET',
          credentials: 'include',
        };
      },
    }),

    readPublicCLoudVirtualMachine: builder.mutation<publicCloudVmDetail, { id: string }>({
      query: ({ id }) => {
        return {
          url: `/v1/public-cloud/readPublicCloudVirtualMachine`,
          method: 'GET',
          params: { id },
          credentials: 'include',
        };
      },
    }),

    /* AWX */
    fetchJob: builder.mutation<Job, { jobId: string | number }>({
      query: (arg) => {
        const { jobId } = arg;

        return {
          url: `/v1/awx/jobs/${jobId}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),

    fetchJobEvents: builder.mutation<JobEvent, { jobId: number | string }>({
      query: (arg) => {
        const { jobId } = arg;

        return {
          url: `/v1/awx/jobs/${jobId}/events`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),

    launchJobTemplate: builder.mutation<LaunchedJobTemplate, { jobTemplateId: string | number; body?: any }>({
      query: (arg) => {
        const { jobTemplateId, body } = arg;

        return {
          url: `/v1/awx/templates/${jobTemplateId}/launch`,
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
    }),

    fetchVmSnapshots: builder.mutation<JobEvent, { jobId: number | string }>({
      query: (arg) => {
        const { jobId } = arg;

        return {
          url: `/v1/awx/jobs/${jobId}/events`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useKeycloakLoginMutation,
  useRecoverPasswordMutation,
  useReadUserMutation,
  useUpdateUserMutation,
  useUploadFileMutation,
  useListUsersMutation,

  useFetchVirtualMachinesMutation,
  useReadVirtualMachineMutation,
  useReadVirtualMachineInterfacesMutation,
  useVirtualMachineActionMutation,
  useVirtualMachineActionGuestMutation,
  useVirtualMachineToggleNAMutation,

  useFetchPublicCloudVirtualMachinesMutation,
  useReadPublicCLoudVirtualMachineMutation,

  useFetchJobMutation,
  useFetchJobEventsMutation,
  useLaunchJobTemplateMutation,
  useFetchVmSnapshotsMutation,
} = api;
