import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';

import { useKeycloakLoginMutation } from '../../app/services/api';

import { useAppSelector, useQuery } from '../../app/hooks/store';

export const AuthCallback: React.FunctionComponent = () => {
  const toast = useToast();
  const query = useQuery();
  const navigate = useNavigate();

  const id_token = query.get('id_token') ?? '';
  const access_token = query.get('access_token') ?? '';
  const refresh_token = query.get('refresh_token') ?? '';

  const { loginStatus } = useAppSelector((state) => state.auth);

  const [keycloakLogin] = useKeycloakLoginMutation();

  const keycloakLoginCallback = React.useCallback(() => {
    keycloakLogin({ access_token, refresh_token, id_token })
      .unwrap()
      .then((user) => {
        toast({
          title: `Connexion réussie !`,
          status: 'success',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });

        navigate('/', { replace: true });
      })
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de se connecter`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [keycloakLogin, toast, navigate, access_token, refresh_token, id_token]);

  React.useEffect(() => {
    keycloakLoginCallback();
  }, [keycloakLoginCallback]);

  const bgColor = useColorModeValue('gray.100', 'gray.900');

  if (loginStatus === 'failed') {
    return (
      <Flex justifyContent={'center'} alignItems={'center'} minH={'100vh'} bg={bgColor}>
        <Text fontSize={20} ml={4} fontWeight={'bold'}>
          Une erreur est survenue. Contactez Partitio si le problème persiste.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent={'center'} alignItems={'center'} minH={'100vh'} bg={bgColor}>
      <Spinner size={'lg'} />
      <Text fontSize={20} ml={4} fontWeight={'bold'}>
        Connexion en cours...
      </Text>
    </Flex>
  );
};
