import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { baseKeycloakUrl } from '../store';
import { BEARER } from '../../common/utils/constant';
import { getToken, setToken } from '../../common/utils/helper';
import { logOut, tokenReceived } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://dashboard.partitio-si-vital.ovh/api',
  // credentials: 'same-origin',
  prepareHeaders: (headers, api) => {
    const token = getToken();

    if (token && api.endpoint !== 'refresh') {
      headers.set('Authorization', `${BEARER} ${token}`);
    }

    return headers;
  },
});

export const refreshTokenFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const sessionId = getToken('sessionId');
  const refresh_token = getToken('refreshToken');

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      {
        method: 'POST',
        credentials: sessionId ? 'omit' : 'include',
        url: sessionId ? `${baseKeycloakUrl}/protocol/openid-connect/token` : '/v1/refreshToken',
        headers: {
          'Content-Type': sessionId ? 'application/x-www-form-urlencoded' : 'application/json',
        },
        body: sessionId
          ? new URLSearchParams({
              grant_type: 'refresh_token',
              refresh_token: refresh_token ?? '',
              client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
              client_secret: process.env.REACT_APP_KEYCLOAK_SECRET ?? '',
            })
          : undefined,
      },
      { ...api, endpoint: 'refresh' },
      extraOptions,
    );

    if (refreshResult.data) {
      if (sessionId) {
        const strapiJwt = await baseQuery(
          {
            url: `/v1/auth/keycloak/callback`,
            method: 'GET',
            credentials: 'omit',
            // @ts-ignore
            params: { access_token: refreshResult.data.access_token, refresh_token },
          },
          { ...api, endpoint: 'refresh' },
          extraOptions,
        );

        if (strapiJwt.data as any) {
          // @ts-ignore
          setToken(strapiJwt.data.jwt);
          // @ts-ignore
          setToken(refreshResult.data.id_token, 'sessionId');
          // @ts-ignore
          setToken(refreshResult.data.refresh_token, 'refreshToken');
        }
      } else {
        api.dispatch(tokenReceived(refreshResult.data));
      }

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};
