import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { User } from '../../app/types/user';
import { baseFrontUrl, baseKeycloakUrl, RootState } from '../../app/store';

import { api } from '../../app/services/api';

import { getToken, removeToken, setToken } from '../../common/utils/helper';

const authToken = getToken();

interface AuthState {
  email: string;
  password: string;

  user?: User;

  signedIn: boolean;

  error?: string | SerializedError;
  loginStatus: 'idle' | 'loading' | 'failed';
}

const initialState: AuthState = {
  email: '',
  password: '',

  signedIn: !!authToken ?? false,

  loginStatus: 'idle',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOutState: () => {
      return { ...initialState, signedIn: false };
    },

    logOut: () => {
      const sessionId = getToken('sessionId');

      if (!sessionId) {
        removeToken();

        return { ...initialState, signedIn: false };
      } else {
        window.location.replace(
          `${baseKeycloakUrl}/protocol/openid-connect/logout?post_logout_redirect_uri=${baseFrontUrl}/logout&id_token_hint=${sessionId}`,
        );
        // removeToken();
        // removeToken('sessionId');
        // removeToken('refreshToken');

        // return { ...initialState, signedIn: false };
      }
    },

    tokenReceived: (state, { payload }) => {
      setToken(payload.jwt);
    },

    setEmailRecovery: (state, { payload }) => {
      state.email = payload;
    },
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addMatcher(api.endpoints.login.matchPending, (state) => {
      state.loginStatus = 'loading';
    });
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.signedIn = true;
      state.user = payload.user;
    });
    builder.addMatcher(api.endpoints.login.matchRejected, (state, { error }) => {
      state.error = error;
      state.loginStatus = 'failed';
    });

    // KEYCLOAK LOGIN
    builder.addMatcher(api.endpoints.keycloakLogin.matchPending, (state) => {
      state.loginStatus = 'loading';
    });
    builder.addMatcher(api.endpoints.keycloakLogin.matchFulfilled, (state, { payload }) => {
      state.signedIn = true;
      state.user = payload.user;
    });
    builder.addMatcher(api.endpoints.keycloakLogin.matchRejected, (state, { error }) => {
      state.error = error;
      state.loginStatus = 'failed';
    });
  },
});

export const { tokenReceived, setEmailRecovery, logOut, logOutState } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
