import * as React from 'react';

import { useParams } from 'react-router-dom';

import { HiRefresh } from 'react-icons/hi';
import { JobStateIndicator } from '../../common/components/status';
import { Text, Heading, useToast, Stack, Code, IconButton, Tooltip, Box, Spinner, useColorModeValue } from '@chakra-ui/react';

import { useAppSelector } from '../../app/hooks/store';
import { useFetchJobMutation, useFetchJobEventsMutation, useReadPublicCLoudVirtualMachineMutation } from '../../app/services/api';

export const JobDetails: React.FunctionComponent = () => {
  const toast = useToast();
  const { vmId, jobId } = useParams();
  const interval = React.useRef<any>(null);

  const { selectedVm } = useAppSelector((state) => state.vms);
  const { selectedPublicCloudVm } = useAppSelector((state) => state.publicCloud);
  const { selectedJob, jobsStatus, jobOutputStatus } = useAppSelector((state) => state.jobs);

  const [fetchJob] = useFetchJobMutation();
  const [fetchJobEvents] = useFetchJobEventsMutation();
  const [readPublicCloudVirtualMachinesMutation] = useReadPublicCLoudVirtualMachineMutation();

  const readVirtualMachines = React.useCallback(() => {
    readPublicCloudVirtualMachinesMutation({ id: vmId ?? '' })
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer la VM`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [readPublicCloudVirtualMachinesMutation, vmId, toast]);

  const fetchJobInfos = React.useCallback(() => {
    fetchJob({ jobId: jobId ?? 0 })
      .unwrap()
      .then(() => {
        fetchJobEvents({ jobId: jobId ?? 0 })
          .unwrap()
          .catch((error) => {
            toast({
              title: `${error.status} : Impossible de récupérer les événements du job`,
              description: error.error ?? error.data.error.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      })
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer les détails du job`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        });
      });
  }, [jobId, fetchJob, fetchJobEvents, toast]);

  React.useEffect(() => {
    document.title = 'SI Vital | Détails';

    readVirtualMachines();
  }, [readVirtualMachines]);

  React.useEffect(() => {
    fetchJobInfos();

    if (selectedJob.info?.status === 'running' || selectedJob.info?.status === 'pending' || selectedJob.info?.status === 'waiting') {
      interval.current = setInterval(() => fetchJobInfos(), 10000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => clearInterval(interval.current);
  }, [fetchJobInfos, selectedJob.info?.status]);

  const bgColor = useColorModeValue('gray.50', 'gray.800');

  return jobsStatus === 'loading' ? (
    <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10} mb={4}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
        <Tooltip label={'Rafraichir'} placement='left'>
          <IconButton
            right={0}
            size={'sm'}
            fontSize='18px'
            isDisabled={false}
            aria-label='refresh'
            icon={<HiRefresh />}
            position={'absolute'}
            onClick={() => fetchJobInfos()}
          />
        </Tooltip>

        <Spinner mb={3} />

        <Text as={'i'} textAlign={'center'}>
          Récupération des données du job... <br /> Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
        </Text>
      </Box>
    </Stack>
  ) : (
    <React.Fragment>
      <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} gap={5} borderWidth={1} borderRadius={10} mb={4}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Heading fontSize={{ base: '2xl' }}>{selectedVm?.name ?? selectedPublicCloudVm?.name}</Heading>

          <Tooltip label={'Rafraichir'} placement='left'>
            <IconButton size={'sm'} fontSize='18px' isDisabled={false} aria-label='refresh' icon={<HiRefresh />} onClick={() => fetchJobInfos()} />
          </Tooltip>
        </Box>

        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Text fontSize={'l'}>{selectedJob.info?.name}</Text>

          <JobStateIndicator status={selectedJob.info?.status} />
        </Box>

        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Text fontSize={'l'}>
            Commencé le : {`${selectedJob.info?.started ? new Date(selectedJob.info?.started as Date).toLocaleString('fr-FR') : '-'}`}
          </Text>

          <Text fontSize={'l'}>
            Fini le : {`${selectedJob.info?.finished ? new Date(selectedJob.info?.finished as Date).toLocaleString('fr-FR') : '-'}`}
          </Text>
        </Box>
      </Stack>

      {jobOutputStatus === 'loading' ? (
        <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10} mb={4}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
            <Tooltip label={'Rafraichir'} placement='left'>
              <IconButton
                right={0}
                size={'sm'}
                fontSize='18px'
                isDisabled={false}
                aria-label='refresh'
                icon={<HiRefresh />}
                position={'absolute'}
                onClick={() => fetchJobInfos()}
              />
            </Tooltip>

            <Spinner mb={3} />

            <Text as={'i'} textAlign={'center'}>
              Récupération des données de l'output du job... <br /> Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
            </Text>
          </Box>
        </Stack>
      ) : (
        <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} gap={5} borderWidth={1} borderRadius={10} mb={4}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Heading fontSize={{ base: '2xl' }}>Job output</Heading>

            <Tooltip label={'Rafraichir'} placement='left'>
              <IconButton
                size={'sm'}
                fontSize='18px'
                isDisabled={false}
                aria-label='refresh'
                icon={<HiRefresh />}
                onClick={() => fetchJobEvents({ jobId: jobId ?? '' })}
              />
            </Tooltip>
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Code width={'100%'} p={2} borderRadius={5}>
              {selectedJob.events.results.length ? [...selectedJob.events.results].pop()?.event_display : 'Aucun output'}

              {!!Object.entries([...selectedJob.events.results].pop()?.event_data ?? {}).length && (
                <React.Fragment>
                  <br />
                  <br />
                  {Object.entries([...selectedJob.events.results].pop()?.event_data ?? {})
                    .filter(([key]) => key === 'changed' || key === 'failures' || key === 'ignored' || key === 'processed' || key === 'ok')
                    .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)} : ${!Object.keys(value).length ? 0 : Object.values(value)}`)
                    .join(', ')}
                </React.Fragment>
              )}
            </Code>
          </Box>
        </Stack>
      )}
    </React.Fragment>
  );
};
