import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { FiMenu } from 'react-icons/fi';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Menu,
  Drawer,
  Avatar,
  HStack,
  Button,
  MenuList,
  MenuItem,
  IconButton,
  MenuButton,
  MenuDivider,
  useColorMode,
  DrawerContent,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';

import { SidebarContent } from '../sidebar';
import { User } from '../../../../app/types/user';
import { useAppSelector } from '../../../../app/hooks/store';

interface MobileProps {
  user?: User;
  onOpen: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MobileNav: React.FunctionComponent<MobileProps> = (props) => {
  const { onOpen, onClick } = props;

  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const { user } = useAppSelector((state) => state.users);

  return (
    <Flex
      height='20'
      alignItems='center'
      borderBottomWidth='1px'
      px={{ base: 4, md: 4 }}
      ml={{ base: 0, md: 60 }}
      bg={useColorModeValue('gray.50', 'gray.800')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <IconButton display={{ base: 'flex', md: 'none' }} onClick={onOpen} variant='outline' aria-label='open menu' icon={<FiMenu />} />

      <Text display={{ base: 'flex', md: 'none' }} fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
        SI Vital
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Button mr={5} onClick={toggleColorMode}>
            {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </Button>

          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'sm'} src={!!user ? user.avatar?.url : '?'} />
              </HStack>
            </MenuButton>

            <MenuList bg={useColorModeValue('white', 'gray.700')} borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={() => navigate('/profil')}>Profil</MenuItem>

              <MenuDivider />

              <MenuItem onClick={onClick}>Déconnexion</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

interface NavbarProps {
  user?: User | any;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
  const { user, onClick, children } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH={'100vh'} bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />

      <Drawer autoFocus={false} isOpen={isOpen} placement='left' onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size='full'>
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <MobileNav user={user} onOpen={onOpen} onClick={onClick} />

      <Box ml={{ base: 0, md: 60 }} p='4'>
        {children}
      </Box>
    </Box>
  );
};
