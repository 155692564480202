import * as React from 'react';

import { VscTerminalLinux } from 'react-icons/vsc';
import { FaWindows, FaQuestionCircle } from 'react-icons/fa';
import { Tag, TagLabel, TagRightIcon } from '@chakra-ui/react';

export const OperatingSystem: React.FunctionComponent<{ osType: string }> = (props) => {
  const { osType } = props;

  let guestOs: boolean = true;

  switch (guestOs) {
    case osType?.toUpperCase().includes('LINUX'):
      return (
        <Tag size={'md'} variant='subtle' colorScheme='yellow'>
          <TagLabel>LINUX</TagLabel>
          <TagRightIcon as={VscTerminalLinux} />
        </Tag>
      );
    case osType?.toUpperCase().includes('UBUNTU'):
      return (
        <Tag size={'md'} variant='subtle' colorScheme='yellow'>
          <TagLabel>UBUNTU</TagLabel>
          <TagRightIcon as={VscTerminalLinux} />
        </Tag>
      );
    case osType?.toUpperCase().includes('DEBIAN'):
      return (
        <Tag size={'md'} variant='subtle' colorScheme='yellow'>
          <TagLabel>DEBIAN</TagLabel>
          <TagRightIcon as={VscTerminalLinux} />
        </Tag>
      );
    case osType?.toUpperCase().includes('FREEBSD'):
      return (
        <Tag size={'md'} variant='subtle' colorScheme='orange'>
          <TagLabel>FreeBSD</TagLabel>
          <TagRightIcon as={VscTerminalLinux} />
        </Tag>
      );
    case osType?.toUpperCase().includes('WINDOWS'):
      return (
        <Tag size={'md'} variant='subtle' colorScheme='blue'>
          <TagLabel>WINDOWS</TagLabel>
          <TagRightIcon as={FaWindows} />
        </Tag>
      );
    default:
      return (
        <Tag size={'md'} variant='subtle' colorScheme='blue'>
          <TagLabel>Iconnu</TagLabel>
          <TagRightIcon as={FaQuestionCircle} />
        </Tag>
      );
  }
};
