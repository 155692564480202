import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';

import { logOutState } from './authSlice';
import { useAppDispatch } from '../../app/hooks/store';
import { removeToken } from '../../common/utils/helper';

export const Logout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    removeToken();
    removeToken('sessionId');
    removeToken('refreshToken');

    dispatch(logOutState());

    setTimeout(() => {
      navigate('/auth');
    }, 2000);
  }, [dispatch, navigate]);

  const bgColor = useColorModeValue('gray.100', 'gray.900');

  return (
    <Flex justifyContent={'center'} alignItems={'center'} minH={'100vh'} bg={bgColor}>
      <Spinner size={'lg'} />
      <Text fontSize={20} ml={4} fontWeight={'bold'}>
        Déconnexion en cours...
      </Text>
    </Flex>
  );
};
