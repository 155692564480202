import { createSlice } from '@reduxjs/toolkit';

interface TableState {
  rowPerPage?: number;
}

const initialState: TableState = {
  rowPerPage: 5,
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setPageCount: (state, action) => {
      state.rowPerPage = action.payload;
    },
  },
});

export const { setPageCount } = tableSlice.actions;
export default tableSlice.reducer;
