import * as React from 'react';

import * as Yup from 'yup';

import { Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { User } from '../../../app/types/user';

export const EditProfilSchema = Yup.object().shape({
  username: Yup.string().min(3, "Nom d'utilisateur trop court").required('Requis'),

  email: Yup.string().email('Email invalide').required('Requis'),

  password: Yup.string().min(3, 'Mot de passe trop court'),
});

const columnHelper = createColumnHelper<User>();

export const usersColumns = [
  columnHelper.accessor('username', {
    id: 'username',
    header: () => 'Nom',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('about', {
    id: 'about',
    header: () => 'À propos',
    cell: (info) => (
      <Text maxW={'200px'} isTruncated>
        {info.getValue()}
      </Text>
    ),
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: () => 'Email',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('confirmed', {
    id: 'confirmed',
    header: () => 'Confirmé',
    cell: (info) => {
      const confirmed = info.getValue();

      return <Text>{confirmed ? 'Oui' : 'Non'}</Text>;
    },
  }),
];
