export interface PathGroup {
  resource: string;
  id: string;
  new: boolean;
  link: string;
  search?: string;
}

export const parse: (path: string, search?: string) => PathGroup[] = (path, search) => {
  const queryParts = path.split('?');

  path = queryParts[0];

  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  if (path === '/') {
    return [{ resource: '', id: '', new: false, link: '/' }];
  }

  const parts = path.split('/');

  return parts.map((p, i) => {
    const isNew = p === 'create';
    const searchParams = search?.split('?').pop() ?? '';
    const link = parts.slice(0, i + 1).join('/') || '/';
    const isCategory = i % 2 === 1 || p === 'equipments' || p === 'catalog';
    const resource = (isCategory ? p : parts[i - 1]) || '';

    return { resource, id: isNew || isCategory ? '' : p, new: isNew, link, search: searchParams };
  });
};
