import * as React from 'react';

import { FaPowerOff, FaQuestionCircle } from 'react-icons/fa';
import { Tag, TagLabel, TagRightIcon, Badge } from '@chakra-ui/react';

import { LaunchedJobTemplate } from '../../../app/types/job';

interface vmStatusIndicatorProps {
  state?: string | 'POWERED_OFF' | 'POWERED_ON' | 'SUSPENDED';
}

export const VmStatusIndicator: React.FunctionComponent<vmStatusIndicatorProps> = ({ state }) => {
  switch (state) {
    case 'POWERED_ON':
      return (
        <Tag size={'md'} variant='subtle' colorScheme='green'>
          <TagLabel>ALLUMÉE</TagLabel>
          <TagRightIcon as={FaPowerOff} />
        </Tag>
      );
    case 'ACTIVE':
      return (
        <Tag size={'md'} variant='subtle' colorScheme='green'>
          <TagLabel>ALLUMÉE</TagLabel>
          <TagRightIcon as={FaPowerOff} />
        </Tag>
      );
    case 'INACTIVE':
      return (
        <Tag size={'md'} variant='subtle' colorScheme='red'>
          <TagLabel>ÉTEINTE</TagLabel>
          <TagRightIcon as={FaPowerOff} />
        </Tag>
      );
    case 'POWERED_OFF':
      return (
        <Tag size={'md'} variant='subtle' colorScheme='red'>
          <TagLabel>ÉTEINTE</TagLabel>
          <TagRightIcon as={FaPowerOff} />
        </Tag>
      );
    case 'SUSPENDED':
      return (
        <Tag size={'md'} variant='subtle' colorScheme='red'>
          <TagLabel>SUSPENDUE</TagLabel>
          <TagRightIcon as={FaPowerOff} />
        </Tag>
      );
    case undefined:
      return (
        <Tag size={'md'} variant='subtle' colorScheme='orange'>
          <TagLabel>État inconnu</TagLabel>
          <TagRightIcon as={FaQuestionCircle} />
        </Tag>
      );
  }
};

export const JobStateIndicator: React.FunctionComponent<LaunchedJobTemplate> = ({ status }) => {
  switch (status) {
    case 'successful':
      return (
        <Badge marginTop={0} colorScheme='green' fontSize='1em'>
          Réussi
        </Badge>
      );
    case 'failed':
      return (
        <Badge marginTop={0} colorScheme='red' fontSize='1em'>
          Échoué
        </Badge>
      );
    case 'canceled':
      return (
        <Badge marginTop={0} colorScheme='purple' fontSize='1em'>
          Annulé
        </Badge>
      );
    case 'pending':
      return (
        <Badge marginTop={0} colorScheme='orange' fontSize='1em'>
          En attente
        </Badge>
      );
    case 'waiting':
      return (
        <Badge marginTop={0} colorScheme='yellow' fontSize='1em'>
          En attente
        </Badge>
      );
    case 'running':
      return (
        <Badge marginTop={0} colorScheme='blue' fontSize='1em'>
          En cours
        </Badge>
      );
    default:
      return null;
  }
};
