import * as React from 'react';

import { Modal as ChakraModal, ModalBody, ModalFooter, ModalHeader, ModalContent, ModalOverlay, ModalCloseButton } from '@chakra-ui/react';

interface ModalProps {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;

  bgColor: any;

  title?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const { isOpen, onClose, bgColor, title, body, footer } = props;

  return (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent bgColor={bgColor} borderWidth={1} borderRadius={10}>
        <ModalHeader display={'flex'} justifyContent={!title ? 'end' : 'space-between'} alignItems={'center'}>
          {title}

          <ModalCloseButton position={'unset'} />
        </ModalHeader>

        <ModalBody paddingBottom={!footer ? '1.5rem' : undefined}>{body}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};
