import * as React from 'react';

import { Tag, TagLabel, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { OperatingSystem } from '../../../common/components/os';
import { VmStatusIndicator } from '../../../common/components/status';
import { VmActions, VmSnapshotActions } from '../../../common/components/actions';

import { vCenterVm, vCenterVmSnapshot } from '../../../app/types/vm';

const columnHelper = createColumnHelper<vCenterVm>();
const vmSnapshotColumnHelper = createColumnHelper<vCenterVmSnapshot>();

export const vmsColumns = (onActionButtonClick: (vm: vCenterVm, action: string) => void) => [
  columnHelper.accessor('details.guest_OS', {
    id: 'guest_OS',
    header: () => 'OS',
    cell: (info) => <OperatingSystem osType={info.getValue()} />,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: () => 'Nom',
  }),
  columnHelper.accessor('cpu_count', {
    id: 'cpu_count',
    header: () => 'CPU (CŒUR)',
  }),
  columnHelper.accessor('memory_size_MiB', {
    id: 'memory_size',
    header: () => 'RAM (GB)',
    cell: (info) => {
      const memoryInGiB = (info.getValue() ?? 0) / 1000;

      return Math.trunc(memoryInGiB);
    },
  }),
  columnHelper.accessor('power_state', {
    id: 'power_state',
    header: () => 'État',
    cell: (info) => <VmStatusIndicator state={info.getValue()} />,
  }),
  columnHelper.display({
    id: 'actions',
    enableSorting: false,
    size: 70,
    header: () => 'Action(s)',
    cell: (info) => <VmActions onClick={(action) => onActionButtonClick(info.row.original, action)} power_state={info.row.getValue('power_state')} />,
  }),
];

export const vmsSnapshotsColumns = (onActionButtonClick: (vm: vCenterVmSnapshot, action: string) => void, currentSnapshot?: vCenterVmSnapshot) => [
  vmSnapshotColumnHelper.accessor('state', {
    id: 'state',
    header: () => 'État',
    cell: (info) =>
      info.row.original.id === currentSnapshot?.id ? (
        <Tag size={'md'} variant='subtle' colorScheme='green'>
          <TagLabel>ACTIF</TagLabel>
        </Tag>
      ) : (
        <Tag size={'md'} variant='subtle' colorScheme='red'>
          <TagLabel>INACTIF</TagLabel>
        </Tag>
      ),
  }),
  vmSnapshotColumnHelper.accessor('name', {
    id: 'name',
    header: () => 'Nom',
  }),
  vmSnapshotColumnHelper.accessor('description', {
    id: 'description',
    header: () => 'Description',
    cell: (info) => (
      <Text title={info.row.original.description} maxW={300} overflow={'hidden'} textOverflow={'ellipsis'}>
        {info.row.original.description}
      </Text>
    ),
  }),
  vmSnapshotColumnHelper.accessor('creation_time', {
    id: 'creation_time',
    header: () => 'Date de création',
    cell: (info) => new Date(info.row.original.creation_time).toLocaleString('fr-FR'),
  }),
  vmSnapshotColumnHelper.display({
    id: 'actions',
    enableSorting: false,
    size: 70,
    header: () => 'Action(s)',
    cell: (info) =>
      info.row.original.id === currentSnapshot?.id ? null : (
        <VmSnapshotActions state={info.row.getValue('state')} onClick={(action) => onActionButtonClick(info.row.original, action)} />
      ),
  }),
];
