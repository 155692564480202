import * as React from 'react';

import { IconButton, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react';

import { MdRestore } from 'react-icons/md';
import { VscDebugRestart, VscDebugStop, VscPlay } from 'react-icons/vsc';

import { vCenterVm, vCenterVmSnapshot } from '../../../app/types/vm';
import { publicCloudVmDetail } from '../../../app/types/nextcloud';

interface VmActionsProps extends vCenterVm {
  isFab?: boolean;
  onClick: (action: string) => void;
}

export const VmActions: React.FunctionComponent<VmActionsProps> = ({ isFab, onClick, power_state }) => {
  const { colorMode } = useColorMode();

  const blue = useColorModeValue('blue.500', 'blue.800');
  const red = useColorModeValue('red.500', 'red.800');
  const green = useColorModeValue('green.500', 'green.800');

  switch (power_state) {
    case 'POWERED_ON':
      return (
        <React.Fragment>
          <Tooltip label={'Redémarrer'} placement='left'>
            <IconButton
              onClick={(e) => {
                onClick('restart');
                e.stopPropagation();
              }}
              size={'sm'}
              fontSize='18px'
              variant='solid'
              bgColor={blue}
              _hover={{
                backgroundColor: colorMode === 'dark' ? 'blue.700' : 'blue.600',
              }}
              aria-label='launch'
              mb={isFab ? 2 : undefined}
              mr={!isFab ? 3 : undefined}
              icon={<VscDebugRestart />}
              // sx={{ ':first-of-type': { marginBottom: 2 } }}
            />
          </Tooltip>

          <Tooltip label={'Éteindre'} placement='left'>
            <IconButton
              onClick={(e) => {
                onClick('shutdown');
                e.stopPropagation();
              }}
              size={'sm'}
              fontSize='18px'
              variant='solid'
              bgColor={red}
              _hover={{
                backgroundColor: colorMode === 'dark' ? 'red.700' : 'red.600',
              }}
              aria-label='running'
              icon={<VscDebugStop />}
            />
          </Tooltip>
        </React.Fragment>
      );
    case 'POWERED_OFF':
      return (
        <Tooltip label={'Allumer'} placement='left'>
          <IconButton
            onClick={(e) => {
              onClick('start');
              e.stopPropagation();
            }}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            bgColor={green}
            _hover={{
              backgroundColor: colorMode === 'dark' ? 'green.700' : 'green.600',
            }}
            aria-label='shutdown'
            icon={<VscPlay />}
          />
        </Tooltip>
      );
    case 'SUSPENDED':
      return (
        <Tooltip label={'Allumer'} placement='left'>
          <IconButton
            onClick={(e) => {
              onClick('start');
              e.stopPropagation();
            }}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            bgColor={green}
            _hover={{
              backgroundColor: colorMode === 'dark' ? 'green.700' : 'green.600',
            }}
            aria-label='shutdown'
            icon={<VscPlay />}
          />
        </Tooltip>
      );
    case undefined:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
  }
};

interface VmActionsSnapshotProps extends Partial<vCenterVmSnapshot> {
  onClick: (action: string) => void;
}

export const VmSnapshotActions: React.FunctionComponent<VmActionsSnapshotProps> = ({ onClick, state }) => {
  const { colorMode } = useColorMode();

  const blue = useColorModeValue('blue.500', 'blue.800');

  switch (state) {
    case 'poweredOff':
      return (
        <React.Fragment>
          <Tooltip label={'Restaurer'} placement='left'>
            <IconButton
              onClick={(e) => {
                onClick('restore');
                e.stopPropagation();
              }}
              fontSize='18px'
              variant='solid'
              bgColor={blue}
              _hover={{
                backgroundColor: colorMode === 'dark' ? 'blue.700' : 'blue.600',
              }}
              aria-label='restore'
              // mr={3}
              icon={<MdRestore />}
            />
          </Tooltip>

          {/*<Tooltip label={'Supprimer'} placement='left'>
            <IconButton
              onClick={(e) => {
                onClick('delete');
                e.stopPropagation();
              }}
              fontSize='18px'
              variant='solid'
              bgColor={'red.800'}
              _hover={{
                backgroundColor: 'red.900',
              }}
              aria-label='delete'
              icon={<VscTrash />}
            />
          </Tooltip>*/}
        </React.Fragment>
      );
    case 'poweredOn':
      return (
        <Tooltip label={'Restaurer'} placement='left'>
          <IconButton
            onClick={(e) => {
              onClick('restore');
              e.stopPropagation();
            }}
            fontSize='18px'
            variant='solid'
            bgColor={blue}
            _hover={{
              backgroundColor: colorMode === 'dark' ? 'blue.700' : 'blue.600',
            }}
            aria-label='restore'
            // mr={3}
            icon={<MdRestore />}
          />
        </Tooltip>
      );
    case undefined:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
  }
};

interface VmDockerActionsProps extends Partial<publicCloudVmDetail> {
  onClick: (action: string) => void;
}

export const VmDockerActions: React.FunctionComponent<VmDockerActionsProps> = ({ onClick, status }) => {
  const { colorMode } = useColorMode();

  const red = useColorModeValue('red.500', 'red.800');
  const green = useColorModeValue('green.500', 'green.800');

  switch (status) {
    case 'ACTIVE':
      return (
        <Tooltip label={'Éteindre'} placement='left'>
          <IconButton
            onClick={(e) => {
              onClick('shutdown');
              e.stopPropagation();
            }}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            bgColor={red}
            _hover={{
              backgroundColor: colorMode === 'dark' ? 'red.700' : 'red.600',
            }}
            aria-label='running'
            icon={<VscDebugStop />}
          />
        </Tooltip>
      );
    case 'INACTIVE':
      return (
        <Tooltip label={'Lancer'} placement='left'>
          <IconButton
            onClick={(e) => {
              onClick('start');
              e.stopPropagation();
            }}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            bgColor={green}
            _hover={{
              backgroundColor: colorMode === 'dark' ? 'green.700' : 'green.600',
            }}
            aria-label='restore'
            icon={<VscPlay />}
          />
        </Tooltip>
      );
    case undefined:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip label={'État inconnu'} placement='left'>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            size={'sm'}
            fontSize='18px'
            variant='solid'
            isDisabled={true}
            aria-label='booting'
            icon={<VscDebugRestart />}
          />
        </Tooltip>
      );
  }
};
