import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { HiRefresh } from 'react-icons/hi';
import { useToast } from '@chakra-ui/react';
import { Table } from '../../common/components/table';

import { vmsColumns } from './utils';
import { vCenterVm } from '../../app/types/vm';

import { useAppSelector } from '../../app/hooks/store';
import { useLaunchJobTemplateMutation, useFetchVirtualMachinesMutation } from '../../app/services/api';

export const VirtualMachines: React.FunctionComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { vmData, vmStatus } = useAppSelector((state) => state.vms);

  const [launchJob] = useLaunchJobTemplateMutation();
  const [fetchVirtualMachinesMutation] = useFetchVirtualMachinesMutation();

  const fetchVirtualMachines = React.useCallback(() => {
    fetchVirtualMachinesMutation()
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de lister les VMs`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [fetchVirtualMachinesMutation, toast]);

  React.useEffect(() => {
    document.title = 'SI Vital | Machines Virtuelles';

    fetchVirtualMachines();
  }, [fetchVirtualMachines]);

  const vmActions = React.useCallback(
    (vm: vCenterVm, action: string) => {
      const virtualMachineAction =
        action === 'stop' ? 'Arrêt forcé' : action === 'shutdown' ? 'Arrêt' : action === 'start' ? 'Démarrage' : 'Redémarrage';

      toast({
        title: `${virtualMachineAction} en cours...`,
        description: `Le job "${virtualMachineAction}" va se lancer`,
        status: 'info',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right',
      });

      if (action === 'start') {
        /* HARDCODED NEED TO CHANGE */
        launchJob({ jobTemplateId: 46, body: { vmName: vm.name, vmAction: virtualMachineAction } })
          .unwrap()
          .then((launchedTemplate) => {
            if (launchedTemplate.job) {
              navigate({ pathname: `/vm/${vm.vm}/job/${launchedTemplate.job}` });
            }
          })
          .catch((error) => {
            toast({
              title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      } else if (action === 'shutdown') {
        /* HARDCODED NEED TO CHANGE */
        launchJob({ jobTemplateId: 47, body: { vmName: vm.name, vmAction: virtualMachineAction } })
          .unwrap()
          .then((launchedTemplate) => {
            if (launchedTemplate.job) {
              navigate({ pathname: `/vm/${vm.vm}/job/${launchedTemplate.job}` });
            }
          })
          .catch((error) => {
            toast({
              title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      } else if (action === 'restart') {
        /* HARDCODED NEED TO CHANGE */
        launchJob({ jobTemplateId: 48, body: { vmName: vm.name, vmAction: virtualMachineAction } })
          .unwrap()
          .then((launchedTemplate) => {
            if (launchedTemplate.job) {
              navigate({ pathname: `/vm/${vm.vm}/job/${launchedTemplate.job}` });
            }
          })
          .catch((error) => {
            toast({
              title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      }
    },
    [launchJob, navigate, toast],
  );

  const columns = React.useMemo(() => vmsColumns(vmActions), [vmActions]);

  return (
    <Table
      tableTitle={'Liste des VMs'}
      columns={columns}
      data={vmData.filter((vm) => !vm.details?.guest_OS.includes('FREEBSD'))}
      totalCount={vmData.filter((vm) => !vm.details?.guest_OS.includes('FREEBSD')).length}
      isLoaded={vmStatus !== 'loading'}
      onRowClick={(row) => navigate(`/vm/${row.vm}`)}
      defaultSorting={[{ id: 'guest_OS', desc: false }]}
      loadingMessage={
        <React.Fragment>
          Récupération des VMs...
          <br />
          Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
        </React.Fragment>
      }
      actionButton={{
        icon: <HiRefresh />,
        tooltip: 'Rafraichir',
        onClick: () => fetchVirtualMachines(),
      }}
    />
  );
};
