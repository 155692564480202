import * as React from 'react';

import { FiServer } from 'react-icons/fi';
import { FaPeopleGroup } from 'react-icons/fa6';
import { SimpleGrid, Stack, useToast } from '@chakra-ui/react';

import { StatsCard } from '../../common/components/card';

import { useAppSelector } from '../../app/hooks/store';
import { useFetchPublicCloudVirtualMachinesMutation, useFetchVirtualMachinesMutation } from '../../app/services/api';

export const Home: React.FunctionComponent = () => {
  const toast = useToast();

  const vSphereVms = useAppSelector((state) => state.vms.vmData);

  const [fetchVirtualMachinesMutation] = useFetchVirtualMachinesMutation();
  const [fetchPublicCloudVirtualMachinesMutation] = useFetchPublicCloudVirtualMachinesMutation();

  const fetchVirtualMachines = React.useCallback(() => {
    fetchVirtualMachinesMutation()
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de lister les VMs applicatives`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [fetchVirtualMachinesMutation, toast]);

  const fetchPublicCloudVirtualMachines = React.useCallback(() => {
    fetchPublicCloudVirtualMachinesMutation()
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de lister les VMs Public Cloud`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [fetchPublicCloudVirtualMachinesMutation, toast]);

  React.useEffect(() => {
    document.title = 'Accueil | SI-Vital';

    fetchVirtualMachines();
    fetchPublicCloudVirtualMachines();
  }, [fetchVirtualMachines, fetchPublicCloudVirtualMachines]);

  return (
    <Stack direction={{ base: 'column' }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          icon={<FiServer size={'3em'} />}
          heading={'VMs - ECS disponibles :'}
          description={!vSphereVms.length ? '-' : vSphereVms.filter((vSphereVm) => !vSphereVm.details?.guest_OS.includes('FREEBSD')).length}
          to={'/vm'}
          linkText={'Afficher'}
        />

        <StatsCard
          icon={<FaPeopleGroup size={'3em'} />}
          heading={'VMs collaboratives disponibles :'}
          description={'1'}
          // description={!publicCloudVms.length ? '-' : publicCloudVms.filter((publicCloudVm) => publicCloudVm.name.includes('nextcloud')).length}
          to={'/vm-cloud'}
          linkText={'Afficher'}
        />
      </SimpleGrid>
    </Stack>
  );
};
