import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { api } from '../../app/services/api';
import { vCenterVm, vCenterVmDetails } from '../../app/types/vm';

interface VirtualMachinesState {
  vmData: Array<vCenterVm>;

  selectedVm: Partial<vCenterVmDetails>;

  error?: string | SerializedError;
  vmStatus: 'idle' | 'loading' | 'failed';
  vmActionStatus: 'idle' | 'loading' | 'failed';
  vmSnapshotStatus: 'idle' | 'loading' | 'failed';
}

const initialState: VirtualMachinesState = {
  vmData: [],

  selectedVm: {},

  vmStatus: 'idle',
  vmActionStatus: 'idle',
  vmSnapshotStatus: 'loading',
};

const vmSlice = createSlice({
  name: 'vm',
  initialState,
  reducers: {
    setSelectedVm: (state, action) => {
      state.selectedVm = action.payload;
    },
    setVmSnapshotStatus: (state) => {
      state.vmSnapshotStatus = 'loading';
    },
  },
  extraReducers: (builder) => {
    /* LIST */
    builder.addMatcher(api.endpoints?.fetchVirtualMachines.matchPending, (state, args) => {
      state.vmStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.fetchVirtualMachines.matchFulfilled, (state, action) => {
      state.vmData = action.payload;

      state.vmStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.fetchVirtualMachines.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmStatus = 'failed';
    });

    /* READ */
    builder.addMatcher(api.endpoints?.readVirtualMachine.matchPending, (state, args) => {
      state.vmStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.readVirtualMachine.matchFulfilled, (state, action) => {
      state.selectedVm = action.payload;

      state.vmStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.readVirtualMachine.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmStatus = 'failed';
    });

    /* READ INTERFACES */
    builder.addMatcher(api.endpoints?.readVirtualMachineInterfaces.matchPending, (state, args) => {
      state.vmStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.readVirtualMachineInterfaces.matchFulfilled, (state, action) => {
      state.selectedVm = { ...state.selectedVm, interfaces: action.payload };

      state.vmStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.readVirtualMachineInterfaces.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmStatus = 'failed';
    });

    /* ACTIONS */
    builder.addMatcher(api.endpoints?.virtualMachineAction.matchPending, (state, args) => {
      state.vmActionStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.virtualMachineAction.matchFulfilled, (state, action) => {
      const virtualMachineIndex = state.vmData.findIndex((virtualMachine) => virtualMachine.vm === action.meta.arg.originalArgs.id);
      const virtualMachineState = action.meta.arg.originalArgs.action === 'stop' ? 'POWERED_OFF' : 'POWERED_ON';

      state.vmData[virtualMachineIndex].power_state = virtualMachineState;
      state.selectedVm = { ...state.selectedVm, power_state: virtualMachineState };

      state.vmActionStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.virtualMachineAction.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmActionStatus = 'failed';
    });

    /* ACTIONS GUEST */
    builder.addMatcher(api.endpoints?.virtualMachineActionGuest.matchPending, (state, args) => {
      state.vmActionStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.virtualMachineActionGuest.matchFulfilled, (state, action) => {
      const virtualMachineIndex = state.vmData.findIndex((virtualMachine) => virtualMachine.vm === action.meta.arg.originalArgs.id);
      const virtualMachineState = action.meta.arg.originalArgs.action === 'shutdown' ? 'POWERED_OFF' : 'POWERED_ON';

      state.vmData[virtualMachineIndex].power_state = virtualMachineState;
      state.selectedVm = { ...state.selectedVm, power_state: virtualMachineState };

      state.vmActionStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.virtualMachineActionGuest.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmActionStatus = 'failed';
    });

    /* GET VM SNAPSHOTS */
    builder.addMatcher(api.endpoints?.fetchVmSnapshots.matchPending, (state, args) => {
      state.vmSnapshotStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.fetchVmSnapshots.matchFulfilled, (state, action) => {
      const data = action.payload.results.find((event) => {
        return event.summary_fields?.job?.status === 'successful' && event.event === 'runner_on_ok' && event.event_data?.task_action === 'debug';
      });

      if (data) {
        state.selectedVm.snapshots = data?.event_data?.res?.snapshot_info?.guest_snapshots;

        state.vmSnapshotStatus = 'idle';
        state.selectedVm.snapshotStatus = data?.summary_fields?.job?.status;
      }
    });
    builder.addMatcher(api.endpoints?.fetchVmSnapshots.matchRejected, (state, { error }) => {
      state.error = error;

      state.vmSnapshotStatus = 'failed';
    });
  },
});

export const { setSelectedVm, setVmSnapshotStatus } = vmSlice.actions;

export default vmSlice.reducer;
