import * as React from 'react';
import { Navigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import { Home } from '../features/home';
import { Profil } from '../features/users/profile';

import { VirtualMachines } from '../features/vms';
import { VmsDetails } from '../features/vms/details';

import { PublicCloudVirtualMachines } from '../features/public-cloud';
import { PublicCloudVmsDetails } from '../features/public-cloud/details';

import { JobDetails } from '../features/jobs/details';

import { Logout } from '../features/auth/logout';
import { AuthCallback } from '../features/auth/callback';
import { ForgotPasswordForm, KeycloakLogin, Login } from '../features/auth';

import { Error } from '../common/components/not found';

export const EDIT = 'edit';
export const NEW = 'create';

export const RoutesRender: React.FunctionComponent = () => {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          index: true,
          element: <KeycloakLogin />,
        },
        {
          path: 'callback',
          element: <AuthCallback />,
        },
      ],
    },
    {
      path: 'logout',
      element: <Logout />,
    },
    {
      path: 'admin',
      element: <Login />,
    },
    {
      path: 'recover',
      element: <ForgotPasswordForm />,
    },
    {
      path: '/',
      element: <PrivateRoute />,
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
    {
      path: 'vm',
      element: <PrivateRoute />,
      children: [
        {
          index: true,
          element: <VirtualMachines />,
        },
        {
          path: ':vmId',
          children: [
            {
              index: true,
              element: <VmsDetails />,
            },
            {
              path: 'job',
              children: [
                {
                  index: true,
                  element: <Navigate to={{ pathname: '..' }} />,
                },
                {
                  path: ':jobId',
                  element: <JobDetails />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'vm-cloud',
      element: <PrivateRoute />,
      children: [
        {
          index: true,
          element: <PublicCloudVmsDetails />,
          // element: <PublicCloudVirtualMachines />,
        },
        {
          path: ':vmId',
          children: [
            {
              index: true,
              element: <Navigate to={{ pathname: '..' }} />,
            },
            {
              path: 'job',
              children: [
                {
                  index: true,
                  element: <Navigate to={{ pathname: '..' }} />,
                },
                {
                  path: ':jobId',
                  element: <JobDetails />,
                },
              ],
            },
          ],
        },
      ],
    },
    /*{
      path: 'users',
      element: <PrivateRoute />,
      children: [
        {
          index: true,
          element: <Users />,
        },
        {
          path: ':userId',
          children: [
            {
              index: true,
              element: <div>details</div>,
            },
          ],
        },
      ],
    },*/
    {
      path: 'profil',
      element: <PrivateRoute />,
      children: [
        {
          index: true,
          element: <Profil />,
        },
      ],
    },
    {
      path: '*',
      element: <Error />,
    },
  ]);
};
