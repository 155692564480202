import * as React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Code, Flex, Heading, Text } from '@chakra-ui/react';

export const Error: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box textAlign='center' py={10} px={6}>
      <Box display='inline-block'>
        <Flex
          w={'55px'}
          h={'55px'}
          bg={'red.500'}
          rounded={'50px'}
          textAlign='center'
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
        >
          <CloseIcon boxSize={'20px'} color={'white'} />
        </Flex>
      </Box>

      <Heading as='h2' size='xl' mt={6} mb={2}>
        Oups... la page demandée n'existe pas
      </Heading>

      <Text color={'gray.500'}>
        La page <Code>{location.pathname}</Code> que vous avez demandé n'existe pas ou a été déplacée. Contactez un administrateur si le problème
        persiste.
      </Text>

      <Button mt={3} onClick={() => navigate(-1)}>
        Retour
      </Button>
    </Box>
  );
};
