import * as React from 'react';

import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';

import { HiRefresh } from 'react-icons/hi';
import { HiCpuChip } from 'react-icons/hi2';
import { CgSmartphoneRam } from 'react-icons/cg';
import { MdOutlineStorage } from 'react-icons/md';

import { OperatingSystem } from '../../common/components/os';
import { VmDockerActions } from '../../common/components/actions';
import { VmStatusIndicator } from '../../common/components/status';
import {
  Box,
  Text,
  Stack,
  Heading,
  Tooltip,
  Spinner,
  useToast,
  IconButton,
  useColorModeValue,
  Link,
  GridItem,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';

import { setDockerInstanceStatus } from './publiccloudSlice';
import { publicCloudVmDetail } from '../../app/types/nextcloud';
import { useAppDispatch, useAppSelector } from '../../app/hooks/store';
import { useLaunchJobTemplateMutation, useReadPublicCLoudVirtualMachineMutation } from '../../app/services/api';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaNetworkWired } from 'react-icons/fa';

export const PublicCloudVmsDetails: React.FunctionComponent = () => {
  const toast = useToast();
  const { vmId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedPublicCloudVm, vmStatus } = useAppSelector((state) => state.publicCloud);

  const [launchJob] = useLaunchJobTemplateMutation();
  const [readPublicCloudVirtualMachinesMutation] = useReadPublicCLoudVirtualMachineMutation();

  const readVirtualMachines = React.useCallback(() => {
    readPublicCloudVirtualMachinesMutation({ id: vmId ?? '' })
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer la VM`,
          description: error.error ?? error.data.error.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [readPublicCloudVirtualMachinesMutation, vmId, toast]);

  const nextcloudQueryParam = React.useMemo(() => Date.now(), [vmStatus]);

  React.useEffect(() => {
    document.title = 'SI Vital | Détails';

    readVirtualMachines();
  }, [readVirtualMachines]);

  const vmActions = React.useCallback(
    (vm: publicCloudVmDetail, action: string) => {
      const virtualMachineAction =
        action === 'stop' ? 'Arrêt forcé' : action === 'shutdown' ? 'Arrêt' : action === 'start' ? 'Démarrage' : 'Redémarrage';

      toast({
        title: `${virtualMachineAction} en cours...`,
        description: `Le job "${virtualMachineAction}" va se lancer`,
        status: 'info',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right',
      });

      if (action === 'start') {
        launchJob({ jobTemplateId: 84, body: { vmName: vm.name, vmAction: virtualMachineAction } })
          .unwrap()
          .then((launchedTemplate) => {
            if (launchedTemplate.job) {
              navigate({ pathname: `/vm-cloud/${vm.id}/job/${launchedTemplate.job}` });
            }
          })
          .catch((error) => {
            toast({
              title: `${error.status} : Action impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      } else if (action === 'shutdown') {
        launchJob({ jobTemplateId: 85, body: { vmName: vm.name, vmAction: virtualMachineAction } })
          .unwrap()
          .then((launchedTemplate) => {
            if (launchedTemplate.job) {
              navigate({ pathname: `/vm-cloud/${vm.id}/job/${launchedTemplate.job}` });
            }
          })
          .catch((error) => {
            toast({
              title: `${error.status} : Action impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      }
    },
    [launchJob, navigate, toast],
  );

  const sessionToken: any = jwtDecode(localStorage.getItem('sessionId') ?? '');
  const bgColor = useColorModeValue('gray.50', 'gray.800');

  return vmStatus === 'loading' ? (
    <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10} mb={4}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
        <Tooltip label={'Rafraichir'} placement='left'>
          <IconButton
            right={0}
            size={'sm'}
            fontSize='18px'
            isDisabled={false}
            aria-label='refresh'
            icon={<HiRefresh />}
            position={'absolute'}
            onClick={() => readVirtualMachines()}
          />
        </Tooltip>

        <Spinner mb={3} />

        <Text as={'i'} textAlign={'center'}>
          Récupération des données de la VM... <br /> Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
        </Text>
      </Box>
    </Stack>
  ) : (
    <React.Fragment>
      <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} gap={5} borderWidth={1} borderRadius={10} mb={4}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Heading fontSize={{ base: '2xl' }} as={Link} href={sessionToken.nextcloudUrl} isExternal>
            {selectedPublicCloudVm?.name ?? 'Inconnu'}
            <ExternalLinkIcon mx='6px' mb='6px' />
          </Heading>

          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <VmDockerActions status={selectedPublicCloudVm?.status} onClick={(action) => vmActions(selectedPublicCloudVm, action)} />

            <Tooltip label={'Rafraichir'} placement='left'>
              <IconButton
                size={'sm'}
                marginStart={2}
                fontSize='18px'
                isDisabled={false}
                aria-label='refresh'
                icon={<HiRefresh />}
                onClick={() => readVirtualMachines()}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <VmStatusIndicator state={selectedPublicCloudVm?.status} />

          <OperatingSystem osType={selectedPublicCloudVm?.flavor?.osType ?? ''} />
        </Box>
      </Stack>

      <GridItem colSpan={{ base: 1, md: 1, lg: 2 }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10}>
        <Text fontSize={'larger'} mb={4}>
          Hardware VM :
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={HiCpuChip} />
            CPU(s) : {selectedPublicCloudVm?.flavor?.vcpus}
          </ListItem>

          <ListItem>
            <ListIcon as={CgSmartphoneRam} />
            Mémoire : {Math.trunc((selectedPublicCloudVm?.flavor?.ram ?? 0) / 1000)} GB
          </ListItem>

          <ListItem>
            <ListIcon as={MdOutlineStorage} />
            Stockage : {selectedPublicCloudVm?.flavor?.disk}
          </ListItem>

          <ListItem>
            <ListIcon as={FaNetworkWired} />
            Réseau(x) : {selectedPublicCloudVm?.ipAddresses?.map((ipAddress) => ipAddress.ip).join(', ')}
          </ListItem>
        </List>
      </GridItem>

      <img
        style={{ display: 'none' }}
        alt={'nextcloud'}
        onLoad={() => dispatch(setDockerInstanceStatus('UP'))}
        onError={() => dispatch(setDockerInstanceStatus('DOWN'))}
        src={sessionToken.nextcloudUrl + `/core/img/logo/logo.svg?${nextcloudQueryParam}`}
      />
    </React.Fragment>
  );
};
