import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack, useColorModeValue } from '@chakra-ui/react';

import { parse } from './parser';

import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks/store';

interface BreadCrumbItem {
  name: string;
  newName: string;
  itemName: (id: string) => string;
}

const breadCrumbMap: (rootState: Partial<RootState>) => { [key: string]: BreadCrumbItem } = (rootState) => {
  return {
    '*': {
      name: 'Erreur',
      newName: '',
      itemName: () => '',
    },
    '': {
      name: 'Accueil',
      newName: '',
      itemName: () => '',
    },
    vm: {
      name: 'VMs - ECS',
      newName: 'Créer une machine virtuelle',
      itemName: (id) => rootState.vms?.vmData.find((vm) => vm.vm === id)?.name ?? 'Détails VM - ECS',
    },
    job: {
      name: 'Job',
      newName: '',
      itemName: (id) => rootState.jobs?.selectedJob?.info?.name ?? 'Détails job',
    },
    auth: {
      name: 'Authentification',
      newName: 'Créer un utilisateur',
      itemName: (id) => rootState.auth?.user?.username ?? 'Utilisateur',
    },
    users: {
      name: 'Utilisateurs',
      newName: 'Créer un utilisateur',
      itemName: (id) => rootState.auth?.user?.username ?? 'Utilisateur',
    },
    profil: {
      name: 'Profil',
      newName: 'Créer un utilisateur',
      itemName: (id) => rootState.auth?.user?.username ?? 'Utilisateur',
    },
    'vm-cloud': {
      name: 'Collaboratif',
      newName: 'Créer une machine virtuelle',
      itemName: (id) => rootState.publicCloud?.vmData.find((vm) => vm.id === id)?.name ?? 'Détails collaboratif',
    },
  };
};

export const Breadcrumbs: React.FunctionComponent = () => {
  const location = useLocation();

  const vmsState = useAppSelector((state) => state.vms);

  const pathParts = parse(location.pathname, location.search);
  const breadcrumb = breadCrumbMap({ vms: vmsState });

  return (
    <Stack direction={{ base: 'column' }} bgColor={useColorModeValue('gray.50', 'gray.800')} px={5} py={2} borderWidth={1} borderRadius={10} mb={4}>
      <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
        {pathParts.map((value, i) => {
          const last = i === pathParts.length - 1;
          const part = breadcrumb[value.resource];
          const name = value.new ? part?.newName : value.id ? part?.itemName(value.id) : part?.name;

          return (
            <BreadcrumbItem key={name} isCurrentPage={last}>
              <BreadcrumbLink as={Link} to={{ pathname: value.link, search: value.search }}>
                {name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Stack>
  );
};
