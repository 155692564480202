import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { api } from '../../app/services/api';
import { Job, JobEvent } from '../../app/types/job';

import { Pagination } from '../../app/types/pagination';

export interface JobData {
  results?: Array<Job>;
  pagination?: Pagination;
}

interface JobState {
  data: {
    results?: Array<Job>;
    pagination?: Pagination;
  };

  selectedJob: {
    info: Job;
    events: JobEvent;
  };

  error?: string | SerializedError;
  jobsStatus: 'idle' | 'loading' | 'failed';
  jobOutputStatus: 'idle' | 'loading' | 'failed';
}

const initialState: JobState = {
  data: {},
  selectedJob: {
    info: {},
    events: {
      results: [],
    },
  },

  jobsStatus: 'idle',
  jobOutputStatus: 'idle',
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobId: (state, action) => {
      state.selectedJob.info.job_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    // FETCH ONE JOB
    builder.addMatcher(api.endpoints.fetchJob.matchPending, (state) => {
      state.jobsStatus = 'loading';
      state.jobOutputStatus = 'loading';
    });
    builder.addMatcher(api.endpoints.fetchJob.matchFulfilled, (state, action) => {
      state.selectedJob.info = action.payload;

      state.jobsStatus = 'idle';
    });
    builder.addMatcher(api.endpoints.fetchJob.matchRejected, (state, { error }) => {
      state.error = error;

      state.jobsStatus = 'failed';
    });

    // FETCH ONE JOB EVENT
    builder.addMatcher(api.endpoints.fetchJobEvents.matchPending, (state) => {
      state.jobOutputStatus = 'loading';
    });
    builder.addMatcher(api.endpoints.fetchJobEvents.matchFulfilled, (state, action) => {
      state.selectedJob.events.results = action.payload.results;

      state.jobOutputStatus = 'idle';
    });
    builder.addMatcher(api.endpoints.fetchJobEvents.matchRejected, (state, { error }) => {
      state.error = error;

      state.jobOutputStatus = 'failed';
    });
  },
});

export const { setJobId } = jobSlice.actions;

export default jobSlice.reducer;
