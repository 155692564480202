import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Requis'),

  // username: Yup.string().required('Requis'),

  password: Yup.string().min(3, 'Mot de passe trop court').required('Requis'),
});

export const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Requis'),
});
