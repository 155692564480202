import { AUTH_TOKEN } from './constant';

export const getToken = (key?: string) => {
  return localStorage.getItem(key ?? AUTH_TOKEN);
};

export const setToken = (token: string, key?: string) => {
  if (token) {
    localStorage.setItem(key ?? AUTH_TOKEN, token);
  }
};

export const removeToken = (key?: string) => {
  localStorage.removeItem(key ?? AUTH_TOKEN);
};

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
