import * as React from 'react';

import { useFormik } from 'formik';
import { HiPencil, HiRefresh } from 'react-icons/hi';
import { Modal } from '../../common/components/modal';
import {
  Stack,
  Input,
  Button,
  Heading,
  useToast,
  FormLabel,
  InputGroup,
  FormControl,
  FormErrorMessage,
  useColorModeValue,
  InputRightElement,
  Box,
  Tooltip,
  IconButton,
  Spinner,
  Text,
  Center,
  Avatar,
  useDisclosure,
} from '@chakra-ui/react';

import { EditProfilSchema } from './utils';
import { User } from '../../app/types/user';
import { useAppSelector } from '../../app/hooks/store';
import { useReadUserMutation, useUpdateUserMutation, useUploadFileMutation } from '../../app/services/api';

export const Profil: React.FunctionComponent = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user, userStatus } = useAppSelector((state) => state.users);

  const [readUser] = useReadUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [uploadFile] = useUploadFileMutation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const fetchUser = React.useCallback(() => {
    readUser()
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer les informations de l'utilisateur`,
          description: error.error ?? error.data?.error?.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        });
      });
  }, [readUser, toast]);

  React.useEffect(() => {
    document.title = 'Profil | SI-Vital';

    fetchUser();
  }, [fetchUser]);

  const editProfilForm = useFormik<User>({
    enableReinitialize: true,
    initialValues: {
      id: user?.id ?? '',
      username: user?.username ?? '',
      email: user?.email ?? '',
      avatar: user?.avatar ?? {
        url: '',
      },
      password: '',
    },
    validationSchema: EditProfilSchema,
    onSubmit: (values, formikHelpers) => {
      const userAvatarFormData = new FormData();

      userAvatarFormData.append('avatar', values.avatar);

      formikHelpers.setSubmitting(true);

      updateUser(values)
        .unwrap()
        .then(() => {
          if (values.avatar !== editProfilForm.initialValues.avatar) {
            uploadFile({ id: values.avatar.id, file: userAvatarFormData })
              .unwrap()
              .then(() => {
                formikHelpers.setSubmitting(false);
              })
              .catch((error) => {
                formikHelpers.setSubmitting(false);

                toast({
                  title: `${error.status} : Impossible mettre à jour l'avatar de l'utilisateur`,
                  description: error.error ?? error.data?.error?.message,
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                  position: 'bottom-right',
                });
              });
          }

          formikHelpers.setSubmitting(false);

          toast({
            title: `Profil mis à jour !`,
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });

          onClose();
        })
        .catch((error) => {
          formikHelpers.setSubmitting(false);

          toast({
            title: `${error.status} : Impossible de mettre à jour les informations de l'utilisateur`,
            description: error.error ?? error.data?.error?.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    },
  });

  const bgColor = useColorModeValue('gray.50', 'gray.800');

  return userStatus === 'loading' ? (
    <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10} mb={4}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
        <Tooltip label={'Rafraichir'} placement='left'>
          <IconButton
            right={0}
            size={'sm'}
            fontSize='18px'
            isDisabled={false}
            aria-label='refresh'
            icon={<HiRefresh />}
            position={'absolute'}
            onClick={() => fetchUser()}
          />
        </Tooltip>

        <Spinner mb={3} />

        <Text as={'i'} textAlign={'center'}>
          Récupération du profil... <br /> Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
        </Text>
      </Box>
    </Stack>
  ) : (
    <React.Fragment>
      <Stack direction={{ base: 'row' }} bgColor={bgColor} p={5} gap={5} borderWidth={1} borderRadius={10} mb={4}>
        <Avatar size='lg' src={user?.avatar?.url} />

        <Box display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Heading fontSize={{ base: '2xl' }}>{user?.username ?? 'Inconnu'}</Heading>

            <Tooltip label={'Éditer'} placement='left'>
              <IconButton size={'sm'} fontSize='18px' isDisabled={false} aria-label='edit' icon={<HiPencil />} onClick={onOpen} />
            </Tooltip>
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize={'l'}>
              Email : <a href={`mailto:${user?.email}`}>{user?.email}</a>
            </Text>

            <Text fontSize={'l'}>À propos : {user?.about}</Text>
          </Box>
        </Box>
      </Stack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        bgColor={bgColor}
        title={<Heading fontSize={{ base: '2xl' }}>Éditer le profil</Heading>}
        body={
          <form style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }} onSubmit={editProfilForm.handleSubmit}>
            <FormControl id='avatar' isInvalid={editProfilForm.touched.avatar && Boolean(editProfilForm.errors.avatar)}>
              <Stack direction={['column', 'row']} spacing={6}>
                <Center>
                  <Avatar size='xl' src={user?.avatar?.url} />
                </Center>

                <Center w='full'>
                  <Input
                    disabled
                    type='file'
                    name={'avatar'}
                    onBlur={editProfilForm.handleBlur}
                    onChange={editProfilForm.handleChange}
                    sx={{
                      '::file-selector-button': {
                        height: 10,
                        mr: 4,
                        background: 'none',
                        border: 'none',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Center>
              </Stack>
            </FormControl>

            <FormControl id='username' isInvalid={editProfilForm.touched.username && Boolean(editProfilForm.errors.username)}>
              <FormLabel>Nom d'utilisateur</FormLabel>
              <Input
                type={'username'}
                name={'username'}
                placeholder={"Nom d'utilisateur"}
                onBlur={editProfilForm.handleBlur}
                value={editProfilForm.values.username}
                onChange={editProfilForm.handleChange}
              />
              {editProfilForm.touched.username && <FormErrorMessage>{editProfilForm.errors.username}</FormErrorMessage>}
            </FormControl>

            <FormControl id='email' isInvalid={editProfilForm.touched.email && Boolean(editProfilForm.errors.email)}>
              <FormLabel>Email</FormLabel>
              <Input
                type={'email'}
                name={'email'}
                placeholder={'exemple@mail.com'}
                onBlur={editProfilForm.handleBlur}
                value={editProfilForm.values.email}
                onChange={editProfilForm.handleChange}
              />
              {editProfilForm.touched.email && <FormErrorMessage>{editProfilForm.errors.email}</FormErrorMessage>}
            </FormControl>

            <FormControl id='password' isInvalid={editProfilForm.touched.password && Boolean(editProfilForm.errors.password)}>
              <FormLabel>Mot de passe</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name={'password'}
                  placeholder={'•••••••••••••'}
                  onBlur={editProfilForm.handleBlur}
                  value={editProfilForm.values.password}
                  onChange={editProfilForm.handleChange}
                />

                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {editProfilForm.touched.password && <FormErrorMessage>{editProfilForm.errors.password}</FormErrorMessage>}
            </FormControl>

            <Stack width={'100%'} spacing={6} direction={['column', 'row']}>
              <Button
                w={'100%'}
                bg={'red.400'}
                color={'white'}
                type={'button'}
                _hover={{
                  bg: 'red.500',
                }}
                onClick={onClose}
              >
                Annuler
              </Button>

              <Button
                w={'100%'}
                bg={'blue.400'}
                color={'white'}
                type={'submit'}
                _hover={{
                  bg: 'blue.500',
                }}
                isLoading={editProfilForm.isSubmitting}
                isDisabled={!editProfilForm.isValid || !editProfilForm.dirty}
              >
                Mettre à jour
              </Button>
            </Stack>
          </form>
        }
      />
    </React.Fragment>
  );
};
