import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { HiRefresh } from 'react-icons/hi';
import { HiCpuChip } from 'react-icons/hi2';
import { FaNetworkWired } from 'react-icons/fa';
import { CgSmartphoneRam } from 'react-icons/cg';
import { MdOutlineStorage } from 'react-icons/md';

import { Table } from '../../common/components/table';
import { VmActions } from '../../common/components/actions';
import { OperatingSystem } from '../../common/components/os';
import { VmStatusIndicator } from '../../common/components/status';
import {
  Box,
  Text,
  List,
  Stack,
  Heading,
  Tooltip,
  Spinner,
  ListItem,
  useToast,
  GridItem,
  ListIcon,
  IconButton,
  useColorModeValue,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from '@chakra-ui/react';

import { vmsSnapshotsColumns } from './utils';
import { formatBytes } from '../../common/utils/helper';
import { vCenterVm, vCenterVmSnapshot } from '../../app/types/vm';
import { useAppDispatch, useAppSelector } from '../../app/hooks/store';

import {
  useLaunchJobTemplateMutation,
  useReadVirtualMachineMutation,
  useReadVirtualMachineInterfacesMutation,
  useFetchVmSnapshotsMutation,
} from '../../app/services/api';
import { setJobId } from '../jobs/jobsSlice';

export const VmsDetails: React.FunctionComponent = () => {
  const toast = useToast();
  const { vmId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const interval = React.useRef<any>(null);

  const { selectedJob } = useAppSelector((state) => state.jobs);
  const { selectedVm, vmStatus, vmSnapshotStatus } = useAppSelector((state) => state.vms);

  const [launchJob] = useLaunchJobTemplateMutation();
  const [readVirtualMachinesMutation] = useReadVirtualMachineMutation();
  const [readVirtualMachinesSnapshotsMutation] = useFetchVmSnapshotsMutation();
  const [readVirtualMachinesInterfacesMutation] = useReadVirtualMachineInterfacesMutation();

  const readVirtualMachines = React.useCallback(() => {
    readVirtualMachinesMutation({ id: vmId ?? '' })
      .unwrap()
      .then(() => {
        readVirtualMachinesInterfacesMutation({ id: vmId ?? '' })
          .unwrap()
          .catch((error) => {
            toast({
              title: `${error.status} : Impossible de récupérer les interfaces de la VM`,
              description: error.error ?? error.data.error.message,
              status: 'error',
              duration: 4000,
              position: 'bottom-right',
              isClosable: true,
            });
          });

        launchJob({ jobTemplateId: 80, body: { vm_id: vmId } })
          .unwrap()
          .then((launchedTemplate) => dispatch(setJobId(launchedTemplate.job)))
          .catch((error) => {
            toast({
              title: `${error.status} : GET VM SNAPSHOTS de la VM impossible`,
              description: error.error ?? error.data?.error?.message,
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-right',
            });
          });
      })
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer la VM`,
          description: error.error ?? error.data?.error?.message,
          status: 'error',
          duration: 4000,
          position: 'bottom-right',
          isClosable: true,
        });
      });
  }, [readVirtualMachinesMutation, readVirtualMachinesInterfacesMutation, launchJob, dispatch, vmId, toast]);

  const getVmSnapshots = React.useCallback(() => {
    readVirtualMachinesSnapshotsMutation({ jobId: selectedJob.info.job_id ?? 0 })
      .unwrap()
      .catch((error) => {
        toast({
          title: `${error.status} : Impossible de récupérer les snapshots de la VM`,
          description: error.error ?? error.data?.error?.message ?? '',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        });
      });
  }, [readVirtualMachinesSnapshotsMutation, selectedJob.info.job_id, toast]);

  React.useEffect(() => {
    document.title = 'SI Vital | Détails';

    readVirtualMachines();
  }, [readVirtualMachines]);

  React.useEffect(() => {
    if (!selectedVm.snapshots?.snapshots?.length) {
      interval.current = setInterval(() => getVmSnapshots(), 10000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => clearInterval(interval.current);
  }, [selectedVm.snapshots?.snapshots?.length, getVmSnapshots]);

  const vmActions = (vm: vCenterVm, action: string) => {
    const virtualMachineAction =
      action === 'stop' ? 'Arrêt forcé' : action === 'shutdown' ? 'Arrêt' : action === 'start' ? 'Démarrage' : 'Redémarrage';

    toast({
      title: `${virtualMachineAction} en cours...`,
      description: `Le job "${virtualMachineAction}" va se lancer`,
      status: 'info',
      duration: 4000,
      isClosable: true,
      position: 'bottom-right',
    });

    if (action === 'start') {
      /* HARDCODED NEED TO CHANGE */
      launchJob({ jobTemplateId: 46, body: { vmName: vm.name, vmAction: virtualMachineAction } })
        .unwrap()
        .then((launchedTemplate) => {
          navigate({ pathname: `job/${launchedTemplate.job}` });
        })
        .catch((error) => {
          toast({
            title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
            description: error.error ?? error.data?.error?.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    } else if (action === 'shutdown') {
      /* HARDCODED NEED TO CHANGE */
      launchJob({ jobTemplateId: 47, body: { vmName: vm.name, vmAction: virtualMachineAction } })
        .unwrap()
        .then((launchedTemplate) => {
          navigate({ pathname: `job/${launchedTemplate.job}` });
        })
        .catch((error) => {
          toast({
            title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
            description: error.error ?? error.data?.error?.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    } else if (action === 'restart') {
      /* HARDCODED NEED TO CHANGE */
      launchJob({ jobTemplateId: 48, body: { vmName: vm.name, vmAction: virtualMachineAction } })
        .unwrap()
        .then((launchedTemplate) => {
          navigate({ pathname: `job/${launchedTemplate.job}` });
        })
        .catch((error) => {
          toast({
            title: `${error.status} : ${virtualMachineAction} de la VM impossible`,
            description: error.error ?? error.data?.error?.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    }
  };

  const snapshotActions = React.useCallback(
    (snapshot: vCenterVmSnapshot) => {
      launchJob({
        // jobTemplateId: 81,
        jobTemplateId: 86,
        body: { vm_id: vmId, snapshot_name: snapshot.name, vm_mac_address: selectedVm?.nics && selectedVm?.nics['4000']?.mac_address },
      })
        .unwrap()
        .then((launchedTemplate) => {
          navigate({ pathname: `job/${launchedTemplate.job}` });
        })
        .catch((error) => {
          toast({
            title: `${error.status} : Restauration de la VM impossible`,
            description: error.error ?? error.data?.error?.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-right',
          });
        });
    },
    [launchJob, navigate, vmId, selectedVm?.nics, toast],
  );

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const columns = React.useMemo(
    () => vmsSnapshotsColumns(snapshotActions, selectedVm.snapshots?.current_snapshot),
    [snapshotActions, selectedVm.snapshots?.current_snapshot],
  );

  return vmStatus === 'loading' ? (
    <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10} mb={4}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position={'relative'}>
        <Tooltip label={'Rafraichir'} placement='left'>
          <IconButton
            right={0}
            size={'sm'}
            fontSize='18px'
            isDisabled={false}
            aria-label='refresh'
            icon={<HiRefresh />}
            position={'absolute'}
            onClick={() => readVirtualMachines()}
          />
        </Tooltip>

        <Spinner mb={3} />

        <Text as={'i'} textAlign={'center'}>
          Récupération des données de la VM... <br /> Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
        </Text>
      </Box>
    </Stack>
  ) : (
    <Tabs size='md' variant='enclosed'>
      <Stack direction={{ base: 'column' }} bgColor={bgColor} p={5} pb={0.6} gap={5} borderWidth={1} borderRadius={10} mb={4}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Heading fontSize={{ base: '2xl' }}>{selectedVm?.name ?? 'Inconnu'}</Heading>

          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <VmActions isFab={false} power_state={selectedVm?.power_state} onClick={(action) => vmActions(selectedVm ?? {}, action)} />

            <Tooltip label={'Rafraichir'} placement='left'>
              <IconButton
                size={'sm'}
                marginStart={2}
                fontSize='18px'
                isDisabled={false}
                aria-label='refresh'
                icon={<HiRefresh />}
                onClick={() => readVirtualMachines()}
              />
            </Tooltip>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <VmStatusIndicator state={selectedVm?.power_state} />

          <OperatingSystem osType={selectedVm?.guest_OS ?? ''} />
        </Box>

        <TabList borderBottom={0}>
          <Tab>Informations</Tab>
          <Tab>Snapshot(s)</Tab>
        </TabList>
      </Stack>

      <TabPanels>
        <TabPanel padding={0}>
          <GridItem colSpan={{ base: 1, md: 1, lg: 2 }} bgColor={bgColor} p={5} borderWidth={1} borderRadius={10}>
            <Text fontSize={'larger'} mb={4}>
              Hardware VM :
            </Text>

            <List spacing={3}>
              <ListItem>
                <ListIcon as={HiCpuChip} />
                CPU(s) : {selectedVm?.cpu?.count}
              </ListItem>

              <ListItem>
                <ListIcon as={CgSmartphoneRam} />
                Mémoire : {Math.trunc((selectedVm?.memory?.size_MiB ?? 0) / 1000)} GB
              </ListItem>

              <ListItem>
                <ListIcon as={MdOutlineStorage} />
                Stockage :{' '}
                {Object.keys(selectedVm?.disks ?? {})
                  .map((diskKey) => {
                    // @ts-ignore
                    const vmDisk = selectedVm?.disks?.[diskKey];

                    return `${vmDisk?.label} : ${formatBytes(vmDisk?.capacity ?? 0)}`;
                  })
                  .join(', ')}
              </ListItem>

              <ListItem>
                <ListIcon as={FaNetworkWired} />
                Réseau(x) :{' '}
                {Object.keys(selectedVm?.interfaces ?? {})
                  .map((interfaceKey) => {
                    // @ts-ignore
                    const vmInterface = selectedVm?.interfaces?.[interfaceKey]?.ip?.ip_addresses?.[0];

                    return `${vmInterface?.ip_address}/${vmInterface?.prefix_length}`;
                  })
                  .join(', ')}
              </ListItem>
            </List>
          </GridItem>
        </TabPanel>

        <TabPanel padding={0}>
          <Table
            isLoaded={vmSnapshotStatus !== 'loading'}
            tableTitle={
              <Heading as={'h3'} size={'md'}>
                Liste des snapshots
              </Heading>
            }
            columns={columns}
            data={selectedVm.snapshots?.snapshots ?? []}
            defaultSorting={[{ id: 'state', desc: true }]}
            totalCount={selectedVm.snapshots?.snapshots.length ?? 0}
            loadingMessage={
              <React.Fragment>
                Récupération des snapshots...
                <br />
                Cela peut prendre quelques instants. N'hésitez pas à rafraichir via le bouton.
              </React.Fragment>
            }
            actionButton={{
              icon: <HiRefresh />,
              tooltip: 'Rafraichir',
              onClick: () => getVmSnapshots(),
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
