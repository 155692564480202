import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import { useAppSelector } from '../app/hooks/store';

export const PrivateRoute: React.FunctionComponent = () => {
  const location = useLocation();

  const { signedIn } = useAppSelector((state) => state.auth);

  return signedIn ? <Outlet /> : <Navigate to='/auth' state={{ from: location }} replace />;
};
