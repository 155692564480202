import * as React from 'react';

import { To, NavLink } from 'react-router-dom';

import { IconType } from 'react-icons';
import { FaPeopleGroup } from 'react-icons/fa6';
import { FiHome, FiServer } from 'react-icons/fi';
import { Box, BoxProps, CloseButton, Flex, FlexProps, Icon, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';

interface NavItemProps extends FlexProps {
  to: To;
  icon: IconType;
  children: React.ReactNode;
  onClick: React.MouseEventHandler<any>;
}

interface LinkItemProps {
  to: To;
  name: string;
  icon: IconType;
  breakAfter?: boolean;
}

const LinkItems: Array<LinkItemProps> = [
  { to: '/', name: 'Accueil', icon: FiHome, breakAfter: true },
  { to: 'vm', name: 'VMs - ECS', icon: FiServer, breakAfter: true },
  { to: 'vm-cloud', name: 'Collaboratif', icon: FaPeopleGroup, breakAfter: true },
];

const NavItem: React.FunctionComponent<NavItemProps> = (props) => {
  const { to, icon, children, onClick } = props;

  const { colorMode } = useColorMode();

  return (
    <Flex
      {...props}
      p='4'
      mx='4'
      to={to}
      role='group'
      as={NavLink}
      align='center'
      cursor='pointer'
      borderRadius='lg'
      onClick={onClick}
      _hover={{
        color: useColorModeValue('gray.800', 'white'),
        bg: useColorModeValue('gray.100', 'gray.700'),
      }}
      _activeLink={{
        backgroundColor: useColorModeValue('gray.200', 'gray.900'),
      }}
    >
      <Icon
        mr='4'
        as={icon}
        fontSize='16'
        _groupHover={{
          color: colorMode === 'light' ? 'gray.800' : 'white',
        }}
      />
      {children}
    </Flex>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export const SidebarContent: React.FunctionComponent<SidebarProps> = (props) => {
  const { onClose } = props;

  return (
    <Box
      {...props}
      h='full'
      pos='fixed'
      borderRight='1px'
      transition='3s ease-in-out'
      w={{ base: 'full', md: 60 }}
      bg={useColorModeValue('gray.50', 'gray.800')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between'>
        <Text as={NavLink} to={'/'} fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
          SI Vital
        </Text>

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {LinkItems.map((link, index) => (
        <NavItem
          key={link.name}
          to={link.to}
          icon={link.icon}
          onClick={onClose}
          mt={index === 0 ? 4 : undefined}
          mb={link.breakAfter ? 5 : undefined}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};
