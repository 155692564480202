import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { store } from './app/store';
import { Provider } from 'react-redux';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { RoutesRender } from './routes';
import { Layout } from './common/components/nav';

import theme from './common/styles/theme';

const container = document.getElementById('root');

if (container === null) {
  throw new Error('root container is missing in index.html');
}

const root = createRoot(container);

root.render(
  /*<React.StrictMode>*/
  <Provider store={store}>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.initialColorMode} />

        <Layout>
          <RoutesRender />
        </Layout>
      </ChakraProvider>
    </BrowserRouter>
  </Provider>,
  /*</React.StrictMode>*/
);
