import * as React from 'react';

import {
  ColumnDef,
  flexRender,
  SortingState,
  useReactTable,
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Flex,
  Thead,
  Tbody,
  Select,
  Tooltip,
  Heading,
  Spinner,
  IconButton,
  useColorMode,
  TableCaption,
  TableContainer,
  useColorModeValue,
  Table as ChakraTable,
} from '@chakra-ui/react';

import { setPageCount } from './tableSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';

interface TableProps {
  isLoaded: boolean;
  onRowClick?: (row: any) => void;

  data: Array<any>;
  columns: Array<ColumnDef<any, any>>;

  defaultSorting?: SortingState;

  totalCount?: React.ReactNode;
  noDataMessage?: React.ReactNode;
  loadingMessage?: React.ReactNode;

  tableTitle?: React.ReactNode;
  tableCaption?: React.ReactNode;

  actionButton?: {
    disabled?: boolean;
    icon?: React.ReactElement;
    tooltip?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
  };
}

export const Table: React.FunctionComponent<TableProps> = (props) => {
  const { isLoaded, onRowClick, data, columns, defaultSorting, noDataMessage, loadingMessage, totalCount, tableTitle, tableCaption, actionButton } =
    props;

  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();

  const { rowPerPage } = useAppSelector((state) => state.table);

  const [sorting, setSorting] = React.useState<SortingState>(defaultSorting ?? []);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: rowPerPage ?? 5,
  });

  const table = useReactTable<any>({
    data: React.useMemo(() => data, [data]),
    columns: React.useMemo(() => columns, [columns]),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <TableContainer bgColor={useColorModeValue('gray.50', 'gray.800')} borderWidth={1} borderRadius={10}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={6}>
        {tableTitle && (
          <Heading as={'h2'} size={'lg'}>
            {tableTitle}
          </Heading>
        )}

        {actionButton && (
          <Tooltip label={actionButton?.tooltip} placement='left'>
            <IconButton
              size={'sm'}
              fontSize='18px'
              aria-label='refresh'
              icon={actionButton?.icon}
              onClick={actionButton?.onClick}
              isDisabled={actionButton?.disabled}
            />
          </Tooltip>
        )}
      </Box>

      <ChakraTable variant='simple'>
        {tableCaption && <TableCaption>{tableCaption}</TableCaption>}

        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const headerId = header.column.id;

                return (
                  <Th key={header.id} colSpan={header.colSpan} isNumeric={headerId === 'actions'}>
                    {header.isPlaceholder ? null : (
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default', userSelect: 'none' }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>

        <Tbody>
          {isLoaded ? (
            table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  id={row.id}
                  key={row.id}
                  cursor={onRowClick && 'pointer'}
                  onClick={(e) => {
                    e.preventDefault();
                    onRowClick && onRowClick(row.original);
                  }}
                  _hover={{
                    color: colorMode === 'light' ? 'gray.800' : 'white',
                    bg: colorMode === 'light' ? 'gray.100' : 'gray.700',
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellId = cell.column.id;

                    return (
                      <Td key={cell.id} isNumeric={cellId === 'actions'}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    );
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={6}>
                  <Box display={'flex'} justifyContent={'center'} p={6}>
                    <Text>{noDataMessage ? noDataMessage : 'Aucune donnée'}</Text>
                  </Box>
                </Td>
              </Tr>
            )
          ) : (
            <Tr>
              <Td textAlign={'center'} colSpan={6}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Spinner mb={loadingMessage ? 3 : 0} />

                  <Text as={'i'}>{loadingMessage}</Text>
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
      </ChakraTable>

      <Flex justifyContent='space-between' p={6} alignItems='center'>
        <Flex alignItems='center'>
          <Text fontWeight='bold' as={'span'}>
            Total : {totalCount}
          </Text>
        </Flex>

        <Flex alignItems={'center'}>
          <Select
            cursor={'pointer'}
            variant={'unstyled'}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
              dispatch(setPageCount(Number(e.target.value)));
            }}
          >
            {[5, 15, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Afficher {pageSize}
              </option>
            ))}
          </Select>

          <Tooltip label='Première page'>
            <IconButton
              mr={4}
              size={'sm'}
              aria-label={'first'}
              icon={<ArrowLeftIcon h={3} w={3} />}
              isDisabled={!table.getCanPreviousPage()}
              onClick={() => table.setPageIndex(0)}
            />
          </Tooltip>

          <Tooltip label='Page précedente'>
            <IconButton
              mr={4}
              size={'sm'}
              aria-label={'previous'}
              onClick={() => table.previousPage()}
              icon={<ChevronLeftIcon h={6} w={6} />}
              isDisabled={!table.getCanPreviousPage()}
            />
          </Tooltip>

          <Text flexShrink='0' mr={4}>
            Page{' '}
            <Text fontWeight='bold' as='span'>
              {table.getState().pagination.pageIndex + 1}
            </Text>{' '}
            sur{' '}
            <Text fontWeight='bold' as='span'>
              {table.getPageCount()}
            </Text>
          </Text>

          <Tooltip label='Page suivante'>
            <IconButton
              mr={4}
              size={'sm'}
              aria-label={'next'}
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>

          <Tooltip label='Dernière page'>
            <IconButton
              size={'sm'}
              aria-label={'last'}
              isDisabled={!table.getCanNextPage()}
              icon={<ArrowRightIcon h={3} w={3} />}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </TableContainer>
  );
};
