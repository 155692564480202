import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { User } from '../../app/types/user';
import { api } from '../../app/services/api';
import { Pagination } from '../../app/types/pagination';

export interface UserData {
  results: Array<User>;
  pagination?: Pagination;
}

interface UsersState {
  data: UserData;

  user?: User;

  error?: string | SerializedError;
  userStatus: 'idle' | 'loading' | 'failed';
}

const initialState: UsersState = {
  data: {
    results: [],
  },

  userStatus: 'idle',
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints?.readUser.matchPending, (state, action) => {
      state.userStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.readUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
      state.userStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.readUser.matchRejected, (state, { error }) => {
      state.error = error;
      state.userStatus = 'failed';
    });

    builder.addMatcher(api.endpoints?.updateUser.matchPending, (state, action) => {
      state.userStatus = 'loading';
    });
    builder.addMatcher(api.endpoints?.updateUser.matchFulfilled, (state, action) => {
      state.user = action.payload;
      state.userStatus = 'idle';
    });
    builder.addMatcher(api.endpoints?.updateUser.matchRejected, (state, { error }) => {
      state.error = error;
      state.userStatus = 'failed';
    });

    builder.addMatcher(api.endpoints.listUsers.matchPending, (state, action) => {
      state.userStatus = 'loading';
    });
    builder.addMatcher(api.endpoints.listUsers.matchFulfilled, (state, action) => {
      state.data = action.payload;
      state.userStatus = 'idle';
    });
    builder.addMatcher(api.endpoints.listUsers.matchRejected, (state, { error }) => {
      state.error = error;
      state.userStatus = 'failed';
    });
  },
});

export default usersSlice.reducer;
