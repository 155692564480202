import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

const theme: ThemeConfig = extendTheme({
  initialColorMode: 'dark',
  useSystemColorMode: false,
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  config: {
    cssVarPrefix: 'style',
    colorModeManager: 'localstorage',
  },
});

export default theme;
