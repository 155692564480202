import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Navbar } from './header';
import { Breadcrumbs } from '../breadcrumbs';

import { logOut } from '../../../features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getToken } from '../../utils/helper';

export const Layout: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  const sessionId = getToken('sessionId');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { signedIn } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    document.title = 'SI Vital | Home';
  }, []);

  const signOut = () => {
    dispatch(logOut());

    if (!sessionId) {
      navigate('/auth');
    }
  };

  return !signedIn ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <React.Fragment>
      <Navbar onClick={signOut}>
        <Breadcrumbs />

        {children}
      </Navbar>
    </React.Fragment>
  );
};
