import * as React from 'react';

import { useFormik } from 'formik';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Link,
  Text,
  Stack,
  Input,
  Button,
  Heading,
  Tooltip,
  useToast,
  FormLabel,
  InputGroup,
  FormControl,
  useColorMode,
  FormErrorMessage,
  useColorModeValue,
  InputRightElement,
} from '@chakra-ui/react';

import { baseApiUrl } from '../../app/store';
import { setEmailRecovery } from './authSlice';
import { LoginSchema, RecoverPasswordSchema } from './utils';
import { useAppDispatch, useAppSelector } from '../../app/hooks/store';
import { LoginRequest, RecoverPasswordRequest } from '../../app/types/auth';
import { useLoginMutation, useRecoverPasswordMutation } from '../../app/services/api';

export const Login: React.FunctionComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { colorMode, toggleColorMode } = useColorMode();

  const [login] = useLoginMutation();
  const { email } = useAppSelector((state) => state.auth);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const loginForm = useFormik<LoginRequest>({
    initialValues: {
      email: email ?? '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, formikHelpers) => {
      setShowPassword(false);
      formikHelpers.setSubmitting(true);

      login({ identifier: values.email, password: values.password })
        .unwrap()
        .then(() => {
          navigate('/');
          formikHelpers.setSubmitting(false);
        })
        .catch((error) => {
          formikHelpers.setSubmitting(false);

          toast({
            title: 'Connexion impossible',
            description: `Erreur : ${error.data.error.message}`,
            status: 'error',
            duration: 4000,
            position: 'bottom-right',
            isClosable: true,
          });
        });
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <form onSubmit={loginForm.handleSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading textAlign={'center'} fontSize={'4xl'}>
              Connectez-vous
            </Heading>

            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
              pour accéder à <Link color={'blue.400'}>SI Vital</Link> et lancer votre PRA.
            </Text>
          </Stack>

          <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
            <Stack spacing={4}>
              <FormControl id='email' isInvalid={loginForm.touched.email && Boolean(loginForm.errors.email)}>
                <FormLabel>Email</FormLabel>
                <Input
                  type={'email'}
                  name={'email'}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.email}
                  placeholder={'exemple@mail.com'}
                  onChange={(e) => {
                    loginForm.handleChange(e);
                    dispatch(setEmailRecovery(e.target.value));
                  }}
                />
                {loginForm.touched.email && <FormErrorMessage>{loginForm.errors.email}</FormErrorMessage>}
              </FormControl>

              <FormControl id='password' isInvalid={loginForm.touched.password && Boolean(loginForm.errors.password)}>
                <FormLabel>Mot de passe</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name={'password'}
                    placeholder={'•••••••••••••'}
                    onBlur={loginForm.handleBlur}
                    value={loginForm.values.password}
                    onChange={loginForm.handleChange}
                  />

                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {loginForm.touched.password && <FormErrorMessage>{loginForm.errors.password}</FormErrorMessage>}
              </FormControl>

              <Stack spacing={2} pt={6} direction={{ base: 'column' }} align={'center'}>
                <Button
                  w={'50%'}
                  bg={'blue.400'}
                  color={'white'}
                  type={'submit'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isLoading={loginForm.isSubmitting}
                  isDisabled={!loginForm.isValid || !loginForm.dirty}
                >
                  Connexion
                </Button>

                <Link as={NavLink} to={'/recover'} pt={3} color={'blue.400'}>
                  Mot de passe oublié ?
                </Link>

                <Button
                  mt={3}
                  w={'100%'}
                  color={'blue.400'}
                  variant={'link'}
                  type={'button'}
                  as={'a'}
                  href={
                    (process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_BASE_URL : process.env.REACT_APP_API_BASE_URL) +
                    '/v1/connect/keycloak'
                  }
                >
                  Connexion via Keycloak
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>

      <Tooltip label={colorMode === 'light' ? 'Dark mode' : 'Light mode'} placement='left'>
        <Button position={'fixed'} top={5} right={5} onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </Tooltip>
    </Flex>
  );
};

export const KeycloakLogin: React.FunctionComponent = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading textAlign={'center'} fontSize={'4xl'}>
            Connectez-vous
          </Heading>

          <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
            pour accéder à <Link color={'blue.400'}>SI Vital</Link> et lancer votre PRA.
          </Text>
        </Stack>

        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <Button
              w={'100%'}
              bg={'blue.400'}
              color={'white'}
              type={'button'}
              _hover={{
                bg: 'blue.500',
              }}
              as={'a'}
              // href={baseApiUrl + '/v1/connect/keycloak'}
              href={`https://dashboard.partitio-si-vital.ovh/api/v1/connect/keycloak`}
            >
              Connexion
            </Button>
          </Stack>
        </Box>
      </Stack>

      <Tooltip label={colorMode === 'light' ? 'Dark mode' : 'Light mode'} placement='left'>
        <Button position={'fixed'} top={5} right={5} onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </Tooltip>
    </Flex>
  );
};

export const ForgotPasswordForm: React.FunctionComponent = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const { email } = useAppSelector((state) => state.auth);
  const [recoverPassword] = useRecoverPasswordMutation();

  const recoverPasswordForm = useFormik<RecoverPasswordRequest>({
    enableReinitialize: true,
    initialValues: {
      email: email ?? '',
    },
    validationSchema: RecoverPasswordSchema,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);

      recoverPassword({ email: values.email })
        .unwrap()
        .then(() => {
          navigate('/auth');
          formikHelpers.setSubmitting(false);

          toast({
            title: 'Email envoyé avec succès !',
            status: 'success',
            duration: 2000,
            position: 'bottom-right',
            isClosable: true,
          });
        })
        .catch((error) => {
          formikHelpers.setSubmitting(false);

          toast({
            title: 'Envoie du mail impossible !',
            description: `Erreur : ${error.data.error.message}`,
            status: 'error',
            duration: 2000,
            position: 'bottom-right',
            isClosable: true,
          });
        });
    },
  });

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <form onSubmit={recoverPasswordForm.handleSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading textAlign={'center'} fontSize={'4xl'}>
              Mot de passe oublié ?
            </Heading>

            <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
              Un email permettant de réinitialiser <br /> votre mot de passe va vous être envoyé.
            </Text>
          </Stack>

          <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
            <Stack spacing={4}>
              <FormControl id='email' isInvalid={recoverPasswordForm.touched.email && Boolean(recoverPasswordForm.errors.email)}>
                <FormLabel>Email</FormLabel>
                <Input
                  type={'email'}
                  name={'email'}
                  placeholder={'mail@mail.com'}
                  onBlur={recoverPasswordForm.handleBlur}
                  value={recoverPasswordForm.values.email}
                  onChange={recoverPasswordForm.handleChange}
                />
                {recoverPasswordForm.touched.email && <FormErrorMessage>{recoverPasswordForm.errors.email}</FormErrorMessage>}
              </FormControl>

              <Stack spacing={2} pt={6} direction={{ base: 'column' }} align={'center'}>
                <Button
                  w={'100%'}
                  bg={'blue.400'}
                  color={'white'}
                  type={'submit'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isLoading={recoverPasswordForm.isSubmitting}
                  isDisabled={!recoverPasswordForm.isValid || !recoverPasswordForm.dirty}
                >
                  Envoyer le mail
                </Button>

                <Link as={NavLink} to={'/auth'} pt={3} color={'blue.400'}>
                  Retour
                </Link>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>

      <Tooltip label={colorMode === 'light' ? 'Dark mode' : 'Light mode'} placement='left'>
        <Button position={'fixed'} top={5} right={5} onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </Tooltip>
    </Flex>
  );
};
